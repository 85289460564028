import React from 'react';

const Footer = () => {
  return (
    <>
        <footer className="footer-wrapper footer-layout1">
            <div className="footer-top">
                <div className="logo-bg"></div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-2">
                            <div className="footer-logo">
                                <a className="icon-masking" href="/">
                                    <span data-mask-src="assets/img/logo-white.svg" className="mask-icon"></span>
                                    <img src="assets/img/logo.jpeg" alt="creativeandinnovative" style={{ height: '95px', width: '110px' , borderRadius:'55px', boxShadow: '0 11px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'  }} />
                                </a>
                            </div>
                        </div>
                        <div className="col-xl-10">
                            <div className="footer-contact-wrap">
                                <div className="footer-contact">
                                    <div className="footer-contact_icon"><i className="fas fa-phone"></i></div>
                                    <div className="media-body">
                                        <span className="footer-contact_text">Quick Call Us:</span> 
                                        <a href="tel:+919784710045" className="footer-contact_link" style={{paddingRight: '15px', fontSize: '15px'}}>+91 97847 10045</a>
                                    </div>
                                </div>
                                <div className="footer-contact">
                                    <div className="footer-contact_icon"><i className="fas fa-envelope"></i></div>
                                    <div className="media-body">
                                        <span className="footer-contact_text">Mail Us On:</span> 
                                        <a href="mailto:info@creativeandinnovative.com" className="footer-contact_link" style={{paddingRight: '15px', fontSize: '15px'}}>info@creativeandinnovative.com</a>
                                    </div>
                                </div>
                                <div className="footer-contact">
                                    <div className="footer-contact_icon"><i className="fas fa-location-dot"></i></div>
                                    <div className="media-body">
                                        <span className="footer-contact_text">Visit Location:</span> 
                                        <a href="https://www.google.com/maps" className="footer-contact_link" style={{paddingRight: '15px', fontSize: '15px'}}>
                                            100 Feet Rd, Shobhagpura, Udaipur, Rajasthan 313001
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="widget-area">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-md-6 col-xxl-3 col-xl-4">
                            <div className="widget footer-widget">
                                <h3 className="widget_title">About Company</h3>
                                <div className="th-widget-about">
                                    <p className="about-text">
                                        Professionally redefine transparent ROI through low-risk high-yield imperatives. Progressively create empowered. cost effective users via team driven.
                                    </p>
                                    <div className="th-social">
                                        <a href="https://www.facebook.com/">
                                            <i className="fab fa-facebook-f"></i>
                                        </a> 
                                        <a href="https://www.twitter.com/">
                                            <i className="fab fa-twitter"></i>
                                        </a> 
                                        <a href="https://www.linkedin.com/">
                                            <i className="fab fa-linkedin-in"></i>
                                        </a> 
                                        <a href="https://www.whatsapp.com/">
                                            <i className="fab fa-whatsapp"></i>
                                        </a> 
                                        <a href="https://www.youtube.com/">
                                            <i className="fab fa-youtube"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-auto">
                            <div className="widget widget_nav_menu footer-widget">
                                <h3 className="widget_title">Quick Links</h3>
                                <div className="menu-all-pages-container">
                                    <ul className="menu">
                                        <li><a href="/">Home</a></li>
                                        <li><a href="/about">About Us</a></li>
                                        <li><a href="/services">Services</a></li>
                                        <li><a href="/contact">Contact Us</a></li>                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-auto">
                            <div className="widget widget_nav_menu footer-widget">
                                <h3 className="widget_title">OUR SERVICES</h3>
                                <div className="menu-all-pages-container">
                                    <ul className="menu">
                                        <li><a href="/service-website-design-development">Website Design/ Development</a></li>
                                        <li><a href="/service-content-writing">Content Writing</a></li>
                                        <li><a href="/service-digital-marketing">Digital Marketing</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-6 col-xl-auto">
                            <div className="widget footer-widget">
                                <h3 className="widget_title">Recent Posts</h3>
                                <div className="recent-post-wrap">
                                    <div className="recent-post">
                                        <div className="media-img">
                                            <a href="blog-details.html">
                                                <img src="assets/img/blog/recent-post-2-1.jpg" alt="Blog Image" />
                                            </a>
                                        </div>
                                        <div className="media-body">
                                            <h4 className="post-title">
                                                <a className="text-inherit" href="blog-details.html">Unsatiable entreaties may collecting Power.</a>
                                            </h4>
                                            <div className="recent-post-meta">
                                                <a href="blog.html">
                                                    <i className="fal fa-calendar-days"></i>
                                                    21 June, 2024
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="recent-post">
                                        <div className="media-img">
                                            <a href="blog-details.html">
                                                <img src="assets/img/blog/recent-post-2-2.jpg" alt="Blog Image" />
                                            </a>
                                        </div>
                                        <div className="media-body">
                                            <h4 className="post-title">
                                                <a className="text-inherit" href="blog-details.html">Regional Manager limited time management.</a>
                                            </h4>
                                            <div className="recent-post-meta">
                                                <a href="blog.html">
                                                    <i className="fal fa-calendar-days"></i>
                                                    22 June, 2024
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="copyright-wrap bg-title">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-6">
                            <p className="copyright-text">
                                Copyright 
                                <i className="fal fa-copyright"></i> 
                                2024 
                                <a href="https://themeforest.net/user/themeholy">Themeholy</a>
                                . All Rights Reserved.
                            </p>
                        </div>
                        {/* <div className="col-lg-6 text-end d-none d-lg-block">
                            <div className="footer-links">
                                <ul>
                                    <li><a href="about.html">Terms & Condition</a></li>
                                    <li><a href="about.html">Careers</a></li>
                                    <li><a href="about.html">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="shape-left">
                <img src="assets/img/shape/footer_shape_2.svg" alt="shape" />
            </div>
            <div className="shape-right">
                <div className="particle-1" id="particle-5"></div>
            </div>
        </footer>
    </>
  )
};

export default Footer;