import React from 'react';
import ProductSlider from '../component/ProductSlider';
const About = () => {
    const cardData = [
        `<div class="swiper-slide card mx-1" style="background: transparent; border: none;">
        
            <div class="th-team team-card">
                <div class="team-img">
                    <img src="assets/img/team/team_1_1.jpg" alt="Team" />
                </div>
                <div class="team-content">
                    <div class="box-particle" id="team-p1"></div>
                    <div class="team-social">
                        <a target="_blank" href="https://facebook.com/">
                            <i class="fab fa-facebook-f"></i>
                        </a> 
                        <a target="_blank" href="https://twitter.com/">
                            <i class="fab fa-twitter"></i>
                        </a> 
                        <a target="_blank" href="https://instagram.com/">
                            <i class="fab fa-instagram"></i>
                        </a> 
                        <a target="_blank" href="https://linkedin.com/">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a>Rayan Athels</a>
                    </h3>
                    <span class="team-desig">Founder & CEO</span>
                </div>
            </div>
        </div>`,
        `<div class="swiper-slide card mx-1" style="background: transparent; border: none;">
        
            <div class="th-team team-card">
                <div class="team-img">
                    <img src="assets/img/team/team_1_2.jpg" alt="Team" />
                </div>
                <div class="team-content">
                    <div class="box-particle" id="team-p2"></div>
                    <div class="team-social">
                        <a target="_blank" href="https://facebook.com/">
                            <i class="fab fa-facebook-f"></i>
                        </a> 
                        <a target="_blank" href="https://twitter.com/">
                            <i class="fab fa-twitter"></i>
                        </a> 
                        <a target="_blank" href="https://instagram.com/">
                            <i class="fab fa-instagram"></i>
                        </a> 
                        <a target="_blank" href="https://linkedin.com/">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a>Alex Furnandes</a>
                    </h3>
                    <span class="team-desig">Project Manager</span>
                </div>
            </div>
        </div>`,
        `<div class="swiper-slide card mx-1" style="background: transparent; border: none;">
        
            <div class="th-team team-card">
                <div class="team-img">
                    <img src="assets/img/team/team_1_3.jpg" alt="Team" />
                </div>
                <div class="team-content">
                    <div class="box-particle" id="team-p3"></div>
                    <div class="team-social">
                        <a target="_blank" href="https://facebook.com/">
                            <i class="fab fa-facebook-f"></i>
                        </a> 
                        <a target="_blank" href="https://twitter.com/">
                            <i class="fab fa-twitter"></i>
                        </a> 
                        <a target="_blank" href="https://instagram.com/">
                            <i class="fab fa-instagram"></i>
                        </a> 
                        <a target="_blank" href="https://linkedin.com/">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a>Mary Crispy</a>
                    </h3>
                    <span class="team-desig">Cheif Expert</span>
                </div>
            </div>
        </div>`,
        `<div class="swiper-slide card mx-1" style="background: transparent; border: none;">
        
            <div class="th-team team-card">
                <div class="team-img">
                    <img src="assets/img/team/team_1_4.jpg" alt="Team" />
                </div>
                <div class="team-content">
                    <div class="box-particle" id="team-p4"></div>
                    <div class="team-social">
                        <a target="_blank" href="https://facebook.com/">
                            <i class="fab fa-facebook-f"></i>
                        </a> 
                        <a target="_blank" href="https://twitter.com/">
                            <i class="fab fa-twitter"></i>
                        </a> 
                        <a target="_blank" href="https://instagram.com/">
                            <i class="fab fa-instagram"></i>
                        </a> 
                        <a target="_blank" href="https://linkedin.com/">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a>Henry Joshep</a>
                    </h3>
                    <span class="team-desig">Product Manager</span>
                </div>
            </div>
        </div>`,
        `<div class="swiper-slide card mx-1" style="background: transparent; border: none;">
        
            <div class="th-team team-card">
                <div class="team-img">
                    <img src="assets/img/team/team_1_5.jpg" alt="Team" />
                </div>
                <div class="team-content">
                    <div class="box-particle" id="team-p5"></div>
                    <div class="team-social">
                        <a target="_blank" href="https://facebook.com/">
                            <i class="fab fa-facebook-f"></i>
                        </a> 
                        <a target="_blank" href="https://twitter.com/">
                            <i class="fab fa-twitter"></i>
                        </a> 
                        <a target="_blank" href="https://instagram.com/">
                            <i class="fab fa-instagram"></i>
                        </a> 
                        <a target="_blank" href="https://linkedin.com/">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a>Sanjida Carlose</a>
                    </h3>
                    <span class="team-desig">IT Consultant</span>
                </div>
            </div>
        </div>`,
        `<div class="swiper-slide card mx-1" style="background: transparent; border: none;">
        
            <div class="th-team team-card">
                <div class="team-img">
                    <img src="assets/img/team/team_1_6.jpg" alt="Team" />
                </div>
                <div class="team-content">
                    <div class="box-particle" id="team-p6"></div>
                    <div class="team-social">
                        <a target="_blank" href="https://facebook.com/">
                            <i class="fab fa-facebook-f"></i>
                        </a> 
                        <a target="_blank" href="https://twitter.com/">
                            <i class="fab fa-twitter"></i>
                        </a> 
                        <a target="_blank" href="https://instagram.com/">
                            <i class="fab fa-instagram"></i>
                        </a> 
                        <a target="_blank" href="https://linkedin.com/">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a>Marian Widjya</a>
                    </h3>
                    <span class="team-desig">Head Manager</span>
                </div>
            </div>
        </div>`,
        `<div class="swiper-slide card mx-1" style="background: transparent; border: none;">
        
            <div class="th-team team-card">
                <div class="team-img">
                    <img src="assets/img/team/team_1_7.jpg" alt="Team" />
                </div>
                <div class="team-content">
                    <div class="box-particle" id="team-p7"></div>
                    <div class="team-social">
                        <a target="_blank" href="https://facebook.com/">
                            <i class="fab fa-facebook-f"></i>
                        </a> 
                        <a target="_blank" href="https://twitter.com/">
                            <i class="fab fa-twitter"></i>
                        </a> 
                        <a target="_blank" href="https://instagram.com/">
                            <i class="fab fa-instagram"></i>
                        </a> 
                        <a target="_blank" href="https://linkedin.com/">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a>Peter Parker</a>
                    </h3>
                    <span class="team-desig">Web Developer</span>
                </div>
            </div>
        </div>`,
        `<div class="swiper-slide card mx-1" style="background: transparent; border: none;">
        
            <div class="th-team team-card">
                <div class="team-img">
                    <img src="assets/img/team/team_1_8.jpg" alt="Team" />
                </div>
                <div class="team-content">
                    <div class="box-particle" id="team-p8"></div>
                    <div class="team-social">
                        <a target="_blank" href="https://facebook.com/">
                            <i class="fab fa-facebook-f"></i>
                        </a> 
                        <a target="_blank" href="https://twitter.com/">
                            <i class="fab fa-twitter"></i>
                        </a> 
                        <a target="_blank" href="https://instagram.com/">
                            <i class="fab fa-instagram"></i>
                        </a> 
                        <a target="_blank" href="https://linkedin.com/">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a>Grayson Gabriel</a>
                    </h3>
                    <span class="team-desig">UI/UX Designer</span>
                </div>
            </div>
        </div>`,



        // Add more HTML strings for additional cards as needed
    ];
    const cardData2 = [
        `
        <div class="swiper-slide card mx-1" style="background: transparent; border: none;">
            <div class="testi-grid">
                <div class="testi-grid_img">
                    <img src="assets/img/testimonial/testi_3_1.jpg" alt="Avater" />
                    <div class="testi-grid_quote">
                        <img src="assets/img/icon/quote_left_3.svg" alt="quote" />
                    </div>
                </div>
                <div class="testi-grid_review">
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                </div>
                <div class="testi-grid_content">
                    <p class="testi-grid_text">Objectively visualize error-free technology for B2B
                        alignment. Monotonectally harness an expanded array of models via effective
                        collaboration. Globally synergize resource sucking value via cutting-edge.
                    </p>
                    <h3 class="box-title">David Farnandes</h3>
                    <p class="testi-grid_desig">CEO at Anaton</p>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide card mx-1" style="background: transparent; border: none;">
            <div class="testi-grid">
                <div class="testi-grid_img">
                    <img src="assets/img/testimonial/testi_3_2.jpg" alt="Avater" />
                    <div class="testi-grid_quote">
                        <img src="assets/img/icon/quote_left_3.svg" alt="quote" />
                    </div>
                </div>
                <div class="testi-grid_review">
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                </div>
                <div class="testi-grid_content">
                    <p class="testi-grid_text">Objectively visualize error-free technology for B2B
                        alignment. Monotonectally harness an expanded array of models via effective
                        collaboration. Globally synergize resource sucking value via cutting-edge.
                    </p>
                    <h3 class="box-title">Jackline Techie</h3>
                    <p class="testi-grid_desig">CEO at Kormola</p>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide card mx-1" style="background: transparent; border: none;">
            <div class="testi-grid">
                <div class="testi-grid_img">
                    <img src="assets/img/testimonial/testi_3_3.jpg" alt="Avater" />
                    <div class="testi-grid_quote">
                        <img src="assets/img/icon/quote_left_3.svg" alt="quote" />
                    </div>
                </div>
                <div class="testi-grid_review">
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                </div>
                <div class="testi-grid_content">
                    <p class="testi-grid_text">Objectively visualize error-free technology for B2B
                        alignment. Monotonectally harness an expanded array of models via effective
                        collaboration. Globally synergize resource sucking value via cutting-edge.
                    </p>
                    <h3 class="box-title">Abraham Khalil</h3>
                    <p class="testi-grid_desig">CEO at Anatora</p>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide card mx-1" style="background: transparent; border: none;">
            <div class="testi-grid">
                <div class="testi-grid_img">
                    <img src="assets/img/testimonial/testi_3_4.jpg" alt="Avater" />
                    <div class="testi-grid_quote">
                        <img src="assets/img/icon/quote_left_3.svg" alt="quote" />
                    </div>
                </div>
                <div class="testi-grid_review">
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                </div>
                <div class="testi-grid_content">
                    <p class="testi-grid_text">Objectively visualize error-free technology for B2B
                        alignment. Monotonectally harness an expanded array of models via effective
                        collaboration. Globally synergize resource sucking value via cutting-edge.
                    </p>
                    <h3 class="box-title">Md Sumon Mia</h3>
                    <p class="testi-grid_desig">CEO at Rimasu</p>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide card mx-1" style="background: transparent; border: none;">
            <div class="testi-grid">
                <div class="testi-grid_img">
                    <img src="assets/img/testimonial/testi_3_1.jpg" alt="Avater" />
                    <div class="testi-grid_quote">
                        <img src="assets/img/icon/quote_left_3.svg" alt="quote" />
                    </div>
                </div>
                <div class="testi-grid_review">
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                </div>
                <div class="testi-grid_content">
                    <p class="testi-grid_text">Objectively visualize error-free technology for B2B
                        alignment. Monotonectally harness an expanded array of models via effective
                        collaboration. Globally synergize resource sucking value via cutting-edge.
                    </p>
                    <h3 class="box-title">David Farnandes</h3>
                    <p class="testi-grid_desig">CEO at Anaton</p>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide card mx-1" style="background: transparent; border: none;">
            <div class="testi-grid">
                <div class="testi-grid_img">
                    <img src="assets/img/testimonial/testi_3_2.jpg" alt="Avater" />
                    <div class="testi-grid_quote">
                        <img src="assets/img/icon/quote_left_3.svg" alt="quote" />
                    </div>
                </div>
                <div class="testi-grid_review">
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                </div>
                <div class="testi-grid_content">
                    <p class="testi-grid_text">Objectively visualize error-free technology for B2B
                        alignment. Monotonectally harness an expanded array of models via effective
                        collaboration. Globally synergize resource sucking value via cutting-edge.
                    </p>
                    <h3 class="box-title">Jackline Techie</h3>
                    <p class="testi-grid_desig">CEO at Kormola</p>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide card mx-1" style="background: transparent; border: none;">
            <div class="testi-grid">
                <div class="testi-grid_img">
                    <img src="assets/img/testimonial/testi_3_3.jpg" alt="Avater" />
                    <div class="testi-grid_quote">
                        <img src="assets/img/icon/quote_left_3.svg" alt="quote" />
                    </div>
                </div>
                <div class="testi-grid_review">
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                </div>
                <div class="testi-grid_content">
                    <p class="testi-grid_text">Objectively visualize error-free technology for B2B
                        alignment. Monotonectally harness an expanded array of models via effective
                        collaboration. Globally synergize resource sucking value via cutting-edge.
                    </p>
                    <h3 class="box-title">Abraham Khalil</h3>
                    <p class="testi-grid_desig">CEO at Anatora</p>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide card mx-1" style="background: transparent; border: none;">
            <div class="testi-grid">
                <div class="testi-grid_img">
                    <img src="assets/img/testimonial/testi_3_4.jpg" alt="Avater" />
                    <div class="testi-grid_quote">
                        <img src="assets/img/icon/quote_left_3.svg" alt="quote" />
                    </div>
                </div>
                <div class="testi-grid_review">
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                </div>
                <div class="testi-grid_content">
                    <p class="testi-grid_text">Objectively visualize error-free technology for B2B
                        alignment. Monotonectally harness an expanded array of models via effective
                        collaboration. Globally synergize resource sucking value via cutting-edge.
                    </p>
                    <h3 class="box-title">Md Sumon Mia</h3>
                    <p class="testi-grid_desig">CEO at Rimasu</p>
                </div>
            </div>
        </div>
    `
    ];
    const cardData3 = [
        `
        <div class="swiper-slide" card mx-1" style="background: transparent; border: none;">
            <div class="blog-card">
                <div class="blog-img">
                    <img src="assets/img/blog/blog_1_1.jpg" alt="blog image" />
                </div>
                <div class="blog-content">
                    <div class="blog-meta">
                        <a>
                            <i class="fal fa-calendar-days"></i>
                            15 Jan, 2024
                        </a> 
                        <a>
                            <i class="fal fa-comments"></i>
                            2 Comments
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a>
                            Unsatiable entreaties may collecting Power.
                        </a>
                    </h3>
                    <div class="blog-bottom">
                        <a class="author">
                            <img src="assets/img/blog/author-1-1.png" alt="avater" />
                            By Themeholy
                        </a> 
                        <a class="line-btn">
                            Read More
                            <i class="fas fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    `,
        `
        <div class="swiper-slide" card mx-1" style="background: transparent; border: none;">
            <div class="blog-card">
                <div class="blog-img">
                    <img src="assets/img/blog/blog_1_2.jpg" alt="blog image" />
                </div>
                <div class="blog-content">
                    <div class="blog-meta">
                        <a>
                            <i class="fal fa-calendar-days"></i>
                            16 Jan, 2024
                        </a> 
                        <a>
                            <i class="fal fa-comments"></i>
                            3 Comments
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a>
                            Regional Manager & limited time management.
                        </a>
                    </h3>
                    <div class="blog-bottom">
                        <a class="author">
                            <img src="assets/img/blog/author-1-1.png" alt="avater" /> 
                            By Themeholy
                        </a> 
                        <a class="line-btn">
                            Read More
                            <i class="fas fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    `,
        `
        <div class="swiper-slide" card mx-1" style="background: transparent; border: none;">
            <div class="blog-card">
                <div class="blog-img">
                    <img src="assets/img/blog/blog_1_3.jpg" alt="blog image" />
                </div>
                <div class="blog-content">
                    <div class="blog-meta">
                        <a>
                            <i class="fal fa-calendar-days"></i>
                            17 Jan, 2024
                        </a> 
                        <a>
                            <i class="fal fa-comments"></i>
                            2 Comments
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a>
                            What’s the Holding Back the It Solution Industry?
                        </a>
                    </h3>
                    <div class="blog-bottom">
                        <a class="author">
                            <img src="assets/img/blog/author-1-1.png" alt="avater" />
                            By Themeholy
                        </a> 
                        <a class="line-btn">
                            Read More
                            <i class="fas fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    `,
        `
        <div class="swiper-slide" card mx-1" style="background: transparent; border: none;">
            <div class="blog-card">
                <div class="blog-img">
                    <img src="assets/img/blog/blog_1_4.jpg" alt="blog image" />
                </div>
                <div class="blog-content">
                    <div class="blog-meta">
                        <a>
                            <i class="fal fa-calendar-days"></i>
                            19 Jan, 2024
                        </a> 
                        <a>
                            <i class="fal fa-comments"></i>
                            4 Comments
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a>
                            Latin derived from Cicero's 1st-century BC
                        </a>
                    </h3>
                    <div class="blog-bottom">
                        <a class="author">
                            <img src="assets/img/blog/author-1-1.png" alt="avater" /> 
                            By Themeholy
                        </a> 
                        <a class="line-btn">
                            Read More
                            <i class="fas fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    `,
        `
        <div class="swiper-slide" card mx-1" style="background: transparent; border: none;">
            <div class="blog-card">
                <div class="blog-img">
                    <img src="assets/img/blog/blog_1_1.jpg" alt="blog image" />
                </div>
                <div class="blog-content">
                    <div class="blog-meta">
                        <a>
                            <i class="fal fa-calendar-days"></i>
                            15 Jan, 2024
                        </a> 
                        <a>
                            <i class="fal fa-comments"></i>
                            2 Comments
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a>
                            Unsatiable entreaties may collecting Power.
                        </a>
                    </h3>
                    <div class="blog-bottom">
                        <a class="author">
                            <img src="assets/img/blog/author-1-1.png" alt="avater" /> 
                            By Themeholy
                        </a> 
                        <a class="line-btn">
                            Read More
                            <i class="fas fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    `,
        `
        <div class="swiper-slide" card mx-1" style="background: transparent; border: none;">
            <div class="blog-card">
                <div class="blog-img">
                    <img src="assets/img/blog/blog_1_2.jpg" alt="blog image" />
                </div>
                <div class="blog-content">
                    <div class="blog-meta">
                        <a>
                            <i class="fal fa-calendar-days"></i>
                            16 Jan, 2024
                        </a> 
                        <a>
                            <i class="fal fa-comments"></i>
                            3 Comments
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a>
                            Regional Manager & limited time management.
                        </a>
                    </h3>
                    <div class="blog-bottom">
                        <a class="author">
                            <img src="assets/img/blog/author-1-1.png" alt="avater" /> 
                            By Themeholy
                        </a> 
                        <a class="line-btn">
                            Read More
                            <i class="fas fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    `,
        `
        <div class="swiper-slide" card mx-1" style="background: transparent; border: none;">
            <div class="blog-card">
                <div class="blog-img">
                    <img src="assets/img/blog/blog_1_3.jpg" alt="blog image" />
                </div>
                <div class="blog-content">
                    <div class="blog-meta">
                        <a>
                            <i class="fal fa-calendar-days"></i>
                            17 Jan, 2024
                        </a> 
                        <a>
                            <i class="fal fa-comments"></i>
                            2 Comments
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a>
                            What’s the Holding Back the It Solution Industry?
                        </a>
                    </h3>
                    <div class="blog-bottom">
                        <a class="author">
                            <img src="assets/img/blog/author-1-1.png" alt="avater" /> 
                            By Themeholy
                        </a> 
                        <a class="line-btn">
                            Read More
                            <i class="fas fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    `,
        `
        <div class="swiper-slide" card mx-1" style="background: transparent; border: none;">
            <div class="blog-card">
                <div class="blog-img">
                    <img src="assets/img/blog/blog_1_4.jpg" alt="blog image" />
                </div>
                <div class="blog-content">
                    <div class="blog-meta">
                        <a>
                            <i class="fal fa-calendar-days"></i>
                            19 Jan, 2024
                        </a> 
                        <a>
                            <i class="fal fa-comments"></i>
                            4 Comments
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a>Latin derived from Cicero's 1st-century BC</a>
                    </h3>
                    <div class="blog-bottom">
                        <a class="author">
                            <img src="assets/img/blog/author-1-1.png" alt="avater" /> 
                            By Themeholy
                        </a> 
                        <a class="line-btn">
                            Read More
                            <i class="fas fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    `
    ];
    return (
        <>           
            <div className="breadcumb-wrapper" style={{
                backgroundImage: `url("assets/img/bg/breadcumb-bg.jpg")`,
                backgroundPosition: 'center'
            }}>
                <div className="container">
                    <div className="breadcumb-content">
                        <h1 className="breadcumb-title">About Us</h1>
                        <ul className="breadcumb-menu">
                            <li><a href="/">Home</a></li>
                            <li>About Us</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="overflow-hidden space" id="about-sec">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 mb-30 mb-xl-0">
                            <div className="img-box1">
                                <div className="img1">
                                    <img src="assets/img/normal/about_1_1.png" alt="About" />
                                </div>
                                <div className="shape1">
                                    <img src="assets/img/normal/about_shape_1.png" alt="shape" />
                                </div>
                                <div className="year-counter">
                                    <h3 className="year-counter_number">
                                        <span className="counter-number">25</span>
                                    </h3>
                                    <p className="year-counter_text">Years Experience</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="ps-xxl-4 ms-xl-3">
                                <div className="title-area mb-35">
                                    <span className="sub-title">
                                        <div className="icon-masking me-2">
                                            <span className="mask-icon" data-mask-src="assets/img/theme-img/title_shape_1.svg"
                                                style={{ background: 'none' }}>
                                            </span>
                                            <img src="assets/img/theme-img/title_shape_1.svg" alt="shape" />
                                        </div>
                                        About Us
                                    </span>
                                    <h2 className="sec-title">
                                        We Are Increasing Business Success With
                                        <span className="text-theme">IT Solution</span>
                                    </h2>
                                </div>
                                <p className="mt-n2 mb-25">
                                    Collaboratively envisioneer user friendly supply chains and cross unit
                                    imperative. Authoritativel fabricate competitive resource and holistic synergy. Uniquely
                                    generate efficient schemas before future.
                                </p>
                                <div className="about-feature-wrap">
                                    <div className="about-feature">
                                        <div className="about-feature_icon">
                                            <img src="assets/img/icon/about_feature_1_1.svg" alt="Icon" />
                                        </div>
                                        <div className="media-body">
                                            <h3 className="about-feature_title">Certified Company</h3>
                                            <p className="about-feature_text">Best Provide Skills Services</p>
                                        </div>
                                    </div>
                                    <div className="about-feature">
                                        <div className="about-feature_icon">
                                            <img src="assets/img/icon/about_feature_1_2.svg" alt="Icon" />
                                        </div>
                                        <div className="media-body">
                                            <h3 className="about-feature_title">Expart Team</h3>
                                            <p className="about-feature_text">100% Expert Team</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="btn-group">
                                    <a href="/about" className="th-btn">
                                        DISCOVER MORE
                                        <i className="fa-regular fa-arrow-right ms-2"></i>
                                    </a>
                                    <div className="call-btn">
                                        <div className="play-btn">
                                            <i className="fas fa-phone"></i>
                                        </div>
                                        <div className="media-body">
                                            <span className="btn-text">Call Us On:</span>
                                            <a href="tel:+919784710045" className="btn-title">+91 97847 10045</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="team-sec space">
                <div className="container z-index-common">
                    <div className="title-area text-center">
                        <span className="sub-title">
                            <div className="icon-masking me-2">
                                <span className="mask-icon" data-mask-src="assets/img/theme-img/title_shape_1.svg" style={{ background: 'none' }}></span>
                                <img src="assets/img/theme-img/title_shape_1.svg" alt="shape" />
                            </div>
                            TEAM MEMBER
                        </span>
                        <h2 className="sec-title">See Our Skilled Expert <span className="text-theme">Team</span></h2>
                    </div>
                    <div className="slider-area">

                        <ProductSlider cardData={cardData} slidesPerView={4} />

                    </div>
                </div>
                <div className="shape-mockup" data-bottom="0" data-left="0">
                    <div className="particle-2" id="particle-2"></div>
                </div>
            </section>
            
            <section className="about-sec-v4 space-bottom" style={{ backgroundImage: `url("assets/img/bg/blog_bg_1.png")` }}>
                <div className="container">
                    <div className="row gy-4 justify-content-center">
                        <div className="col-xl-4 col-md-6">
                            <div className="feature-card" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                <div className="shape-icon">
                                    <img src="assets/img/icon/feature_card_1.png" alt="icon" />
                                </div>
                                <h3 className="box-title">Mission</h3>
                                <p className="feature-card_text">
                                    Provide state-of-the-art services to our clientele in London with cost-effective solutions for business expansion and create a dynamic work environment with collaborative efforts directed at customer satisfaction.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="feature-card" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                <div className="shape-icon">
                                    <img src="assets/img/icon/feature_card_2.png" alt="icon" />
                                </div>
                                <h3 className="box-title">Our Vision</h3>
                                <p className="feature-card_text">
                                    We aim to excel at outsourcing with delicately devised creative outlets and quality service.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="feature-card" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                <div className="shape-icon">
                                    <img src="assets/img/icon/feature_card_3.png" alt="icon" />
                                </div>
                                <h3 className="box-title">Our Value</h3>
                                <p className="feature-card_text">
                                    We curate exceptional designs and frameworks with prompt delivery and seamless performance. With flair and finesse, our team is guided by expert supervision to reach beyond their limits and embrace individuality to serve our patrons.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <div className="space" data-bg-src="assets/img/bg/why_bg_1.png">
                <div className="container">
                    <div className="row align-items-center flex-row-reverse">
                        <div className="col-xxl-7 col-xl-6 mb-30 mb-xl-0">
                            <div className="img-box2">
                                <div className="img1">
                                    <img src="assets/img/normal/why_1_1.jpg" alt="Why" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-6">
                            <div className="title-area mb-35">
                                <span className="sub-title">
                                    <div className="icon-masking me-2">
                                        <span className="mask-icon" data-mask-src="assets/img/theme-img/title_shape_1.svg" style={{ background: 'none' }}></span>
                                        <img src="assets/img/theme-img/title_shape_1.svg" alt="shape" />
                                    </div>
                                    WHY CHOOSE US
                                </span>
                                <h2 className="sec-title">
                                    We Deal With The Aspects Professional
                                    <span className="text-theme">IT Services</span>
                                </h2>
                            </div>
                            <p className="mt-n2 mb-30">
                                Collaboratively envisioneer user friendly supply chains and cross unit
                                imperative. Authoritativel fabricate competitive resource and holistic.
                            </p>
                            <div className="two-column">
                                <div className="checklist style2">
                                    <ul>
                                        <li><i className="far fa-check"></i> Big Data Analysis</li>
                                        <li><i className="far fa-check"></i> 24/7 Online Support</li>
                                        <li><i className="far fa-check"></i> Business Improvement</li>
                                    </ul>
                                </div>
                                <div className="checklist style2">
                                    <ul>
                                        <li><i className="far fa-check"></i> High Quality Sicurity</li>
                                        <li><i className="far fa-check"></i> 24/7 Support Team</li>
                                        <li><i className="far fa-check"></i> Easy Solutions</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="bg-top-center space" style={{ backgroundImage: `url("assets/img/bg/testi_bg_3.jpg")` }} >
                <div className="container" style={{ height: '330px' }}>
                    <div className="title-area text-center">
                        <div className="shadow-title color2">TESTIMONIALS</div>
                            <span className="sub-title">
                                <div className="icon-masking me-2">
                                    <span className="mask-icon" data-mask-src="assets/img/theme-img/title_shape_2.svg" style={{ background: 'none' }}></span> 
                                    <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" />
                                </div>
                                CUSTOMER FEEDBACK
                            </span>
                        <h2 className="sec-title text-white">What Happy Clients Says<br />
                            <span className="text-theme">About Us?</span>
                        </h2>
                    </div>
                    <div className="slider-area">
                        <ProductSlider cardData={cardData2} slidesPerView={3} />
                    </div>
                </div>
            </section>
            <section className="bg-top-right overflow-hidden space-bottom" id="blog-sec" style={{ backgroundImage: `url("assets/img/bg/blog_bg_1.png")`, paddingTop: '370px', paddingBottom: '0px' }} >
                <div className="container space-bottom">
                    <div className="title-area text-center">
                        <span className="sub-title">
                            <div className="icon-masking me-2">
                                <span className="mask-icon" data-mask-src="assets/img/theme-img/title_shape_1.svg" style={{ background: 'none' }}></span> 
                                <img src="assets/img/theme-img/title_shape_1.svg" alt="shape" />
                            </div>
                            NEWS & ARTICLES
                        </span>
                        <h2 className="sec-title">
                            Get Every Single Update 
                            <span className="text-theme">Blog</span>
                        </h2>
                    </div>
                    <div className="slider-area">
                        <ProductSlider cardData={cardData3} slidesPerView={3} />
                    </div>
                </div>

            </section>
            
            <div className="scroll-top">
                <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                        style={{
                            transition: 'stroke-dashoffset 10ms linear 0s',
                            strokeDasharray: '307.919, 307.919',
                            strokeDashoffset: '307.919'
                        }}>
                    </path>
                </svg>
            </div>
           
        </>
    )
};

export default About;