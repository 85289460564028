import React from 'react';

const DigitalMarketing = () => {
    return (
        <>
            <div className="breadcumb-wrapper" style={{
                backgroundImage: `url("assets/img/bg/breadcumb-bg.jpg")`,
                backgroundPosition: 'center'
            }} data-bg-src="">
                <div className="container">
                    <div className="breadcumb-content">
                        <h1 className="breadcumb-title">Services Details</h1>
                        <ul className="breadcumb-menu">
                            <li><a href="/">Home</a></li>
                            <li><a href="/services">Services</a></li>
                            <li>Digital Marketing</li>
                        </ul>
                    </div>
                </div>
            </div>
            <section className="space-top space-extra-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-8 col-lg-8">
                            <div className="page-single">
                                <div className="page-img"><img src="assets/img/service/service_details.jpg" alt="Service Image" />
                                </div>
                                <div className="page-content">
                                    <h2 className="h3 page-title">Digital Marketing</h2> <hr />
                                    <h4>
                                        Overview
                                    </h4>
                                    <h6>
                                        Drive More Revenue with Digital Marketing Services UK
                                    </h6>
                                    <p>
                                        The marketing industry has undergone more change in the last ten years as compared to the last century. We have ushered in the era of websites, reels, hashtags, and Google displacing traditional TV commercials and print advertisements. The average adult spends over 6 hours in digital media consumption, excluding individuals with these domain-specific jobs. <br />
                                        Consumers are connected to the internet around the clock! <br />
                                        So, how can you convert these internet users into high-paying clients? By utilizing top-notch digital marketing services from a successful online marketing firm. Surely, your website is your 24/7 online sales representative and is your most valuable marketing asset. But it isn't easy to find unless you use the right marketing tactics. <br />
                                        Digital marketing services offer companies of all sizes a chance to promote their brand around the clock at a low cost. A Digital Marketing Company UK can assist businesses of any size - from startups to corporations with multiple locations - in expanding their market reach of target customers regardless of time zones or location.
                                    </p>
                                    <h6>
                                        Why Your Business Needs Digital Marketing Services?
                                    </h6>
                                    <p>
                                        The growth of the online marketing sector is unprecedented. As per the 2021 data available on Statista, UK businesses spend over 23.47 billion British pounds on their business digital advertising. As more and more businesses are devoting time and money to online marketing, relying on outdated marketing strategies to attract clients can't help you win customers. <br />
                                        You can boost your sales volume and create a solid digital basis with value-driven digital marketing services. Here's how Digital Marketing Agency London helps your business: <br />
                                        •	Save your resources, time, and money. <br />
                                        •	Establish an online reputation for your business. <br />
                                        •	Achieve great ROI. <br />
                                        •	Increase consumer touchpoints. <br />
                                        •	Track the progress of your campaign. <br />
                                        •	Have increased consumer involvement. <br />
                                        •	Adapt strategies based on detailed analytics. <br />
                                        •	Attain long-term growth. <br />
                                        •	Utilize targeted audience analysis. <br />
                                        •	Boost your conversion rates. <br />
                                        Most marketing professionals believe that a lack of high-quality data is the main obstacle to their lead-generation process. But you can comprehensively understand your client journey and competitors with the support of a reputable Digital Marketing Agency London.
                                    </p>
                                    <h6>
                                        Get a Reliable Partner For "Do-It-For-Me" Digital Marketing
                                    </h6>
                                    <p>
                                        You'll notice this difference immediately if you've previously worked with some digital marketing agency. Creative and Innovative Service Provider cares about your business and your objectives. We understand the fact that although we may have previously worked for your industry, we've never worked for your business. <br />
                                        This is the reason our team asks you a lot of questions. They're interested in knowing what motivates you and what are your long-term objectives. The information we gain from the responses to these questions aids our process of creating long-term plans and marketing objectives for your business. <br />
                                        Access to transparent reports, pricing, and more. <br />
                                        We place a strong emphasis on transparency, unlike other online marketing companies. Whether you're looking for information about our team's strategy for your digital marketing service or the outcomes of your most recent PPC campaign, you can access them anytime. <br />
                                        Additionally, we outline each service and cost when we give your team a custom quote. In this manner, you are certain of what you are receiving. You can monitor the progress of your investment in real time with our experts.
                                    </p>
                                    <h6>
                                        Reasons to Choose Creative and Innovative Service Provider as Your Digital Marketing Agency
                                    </h6>
                                    <p className="mb-30">
                                        Creative and Innovative Service Provider is a digital marketing service provider in the UK that offers goal-oriented marketing solutions. Our goal is to assist businesses in boosting their conversion and client retention rates. You can count on us as we dedicate every available resource to the online success of your brand. Choose Creative and Innovative Service Provider and get access to a plethora of affordable digital marketing solutions: <br />
                                        •	Data-driven Marketing Services <br />
                                        •	Custom Digital Marketing Solutions <br />
                                        •	Campaign Monitoring and Evaluation <br />
                                        •	Competitive Pricing <br />
                                        Spending money on marketing tactics that don't yield quantifiable outcomes wastes your efforts. Partner with Creative and Innovative Service Provider Digital Marketing Service Provider today to focus on digital marketing solutions that actually help your business build dominance in the market.
                                    </p>

                                    <div className="row">
                                        <div className="col-md-6 mb-30">
                                            <div className="th-video"><img className="w-100"
                                                src="assets/img/service/service_inner_1.jpg" alt="service" /> <a
                                                    href="https://www.youtube.com/watch?v=_sI_Ps7JSEk"
                                                    className="play-btn popup-video"><i className="fas fa-play"></i></a></div>
                                        </div>
                                        <div className="col-md-6 mb-30">
                                            <h3 className="h4 mb-20">We Serve The Best Work</h3>
                                            <div className="checklist style5">
                                                <ul>
                                                    <li><i className="fas fa-octagon-check"></i> Up am intention on dependent
                                                        questions</li>
                                                    <li><i className="fas fa-octagon-check"></i> Surrounded affronting favourable
                                                    </li>
                                                    <li><i className="fas fa-octagon-check"></i> Sed ut perspiciatis unde omnis iste
                                                        natus</li>
                                                    <li><i className="fas fa-octagon-check"></i> Special Careness Risk Free</li>
                                                    <li><i className="fas fa-octagon-check"></i> Bibend auctor nisi elit volume are
                                                        beguiled</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-4">Credibly brand accurate data after user friendly ROI. Professionally
                                        negotiate inexpensive markets and team driven scenarios. Interactively seize wireless
                                        e-tailers after resource maximizing content. Collaboratively leverage existing.</p>
                                    <h3 className="h4 mb-20">Benefits With Our Service</h3>
                                    <div className="service-feature-wrap">
                                        <div className="service-feature">
                                            <div className="service-feature_icon"><img src="assets/img/icon/service_feature_1.svg"
                                                alt="icon" /></div>
                                            <div className="media-body">
                                                <h4 className="service-feature_title">Flexible Solutions</h4>
                                                <p className="service-feature_text">Completely grow multimedia based content before
                                                    global scenarios.</p>
                                            </div>
                                        </div>
                                        <div className="service-feature">
                                            <div className="service-feature_icon"><img src="assets/img/icon/service_feature_2.svg"
                                                alt="icon" /></div>
                                            <div className="media-body">
                                                <h4 className="service-feature_title">24/7 Unlimited Support</h4>
                                                <p className="service-feature_text">Completely grow multimedia based content before
                                                    global scenarios.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 className="h4 mt-35 mb-4">Questions About Service</h3>
                                    <div className="accordion-area accordion" id="faqAccordion">
                                        <div className="accordion-card style2 active">
                                            <div className="accordion-header" id="collapse-item-1"><button className="accordion-button"
                                                type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1"
                                                aria-expanded="true" aria-controls="collapse-1">Where can I get analytics
                                                help?</button></div>
                                            <div id="collapse-1" className="accordion-collapse collapse show"
                                                aria-labelledby="collapse-item-1" data-bs-parent="#faqAccordion">
                                                <div className="accordion-body">
                                                    <p className="faq-text">Synergistically evisculate ubiquitous niches whereas
                                                        holistic resources. Assertively evolve prospective methods of
                                                        empowerment before diverse web-readiness. Seamlessly incentivize
                                                        market-driven schemas rather than clicks-and-mortar benefits.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-card style2">
                                            <div className="accordion-header" id="collapse-item-2"><button
                                                className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse-2" aria-expanded="false"
                                                aria-controls="collapse-2">How long should a business plan be?</button>
                                            </div>
                                            <div id="collapse-2" className="accordion-collapse collapse"
                                                aria-labelledby="collapse-item-2" data-bs-parent="#faqAccordion">
                                                <div className="accordion-body">
                                                    <p className="faq-text">Synergistically evisculate ubiquitous niches whereas
                                                        holistic resources. Assertively evolve prospective methods of
                                                        empowerment before diverse web-readiness. Seamlessly incentivize
                                                        market-driven schemas rather than clicks-and-mortar benefits.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-card style2">
                                            <div className="accordion-header" id="collapse-item-3"><button
                                                className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse-3" aria-expanded="false"
                                                aria-controls="collapse-3">How To Choose A Good QA Consultant?</button>
                                            </div>
                                            <div id="collapse-3" className="accordion-collapse collapse"
                                                aria-labelledby="collapse-item-3" data-bs-parent="#faqAccordion">
                                                <div className="accordion-body">
                                                    <p className="faq-text">Synergistically evisculate ubiquitous niches whereas
                                                        holistic resources. Assertively evolve prospective methods of
                                                        empowerment before diverse web-readiness. Seamlessly incentivize
                                                        market-driven schemas rather than clicks-and-mortar benefits.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-lg-4">
                            <aside className="sidebar-area">
                                <div className="widget widget_nav_menu">
                                    <h3 className="widget_title">All Services</h3>
                                    <div className="menu-all-pages-container">
                                        <ul className="menu">
                                            <li><a href="/service-website-design-development">Website Design And Development</a></li>
                                            <li><a href="/service-content-writing">Content Writing</a></li>
                                            <li><a href="/service-digital-marketing">Digital Marketing</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="widget widget_download">
                                    <h4 className="widget_title">Download Brochure</h4>
                                    <div className="download-widget-wrap"><a className="th-btn"><i
                                        className="fa-light fa-file-pdf me-2"></i>DOWNLOAD PDF</a> <a
                                             className="th-btn style5"><i
                                                className="fa-light fa-file-lines me-2"></i>DOWNLOAD DOC</a></div>
                                </div>
                                <div className="widget widget_banner"
                                    style={{
                                        backgroundImage: `url("assets/img/bg/widget_banner.jpg")`
                                    }} data-bg-src="">
                                    <div className="widget-banner"><span className="text">CONTACT US NOW</span>
                                        <h2 className="title">You Need Help?</h2><a href="/contact" className="th-btn style3">GET A
                                            QUOTE<i className="fas fa-arrow-right ms-2"></i></a>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>            
            <div className="scroll-top"><svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                    style={{
                        transition: 'stroke-dashoffset 10ms linear 0s',
                        strokeDasharray: '307.919, 307.919',
                        strokeDashoffset: '307.919'
                    }}>
                </path>
            </svg></div>
        </>
    )
};

export default DigitalMarketing;