
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Index from './pages';
import About from './pages/about';
import Contact from './pages/contact';
import Services from './pages/services';
import ServiceDetails from './pages/service-details';
import WebsiteDesignDevelopment from './pages/service-pages/WebsiteDesign-Development';
import VoiceSupport from './pages/service-pages/VoiceSupport';
import OmniSupport from './pages/service-pages/OmniSupport';
import VirtualLearning from './pages/service-pages/VirtualLearning';
import AccountingAndBookkeepingServices from './pages/service-pages/AccountingAndBookkeepingServices';
import ECommerceMaintenance from './pages/service-pages/E-CommerceMaintenance';
import DataManagement from './pages/service-pages/DataManagement';
import ContentWriting from './pages/service-pages/ContentWriting';
import DigitalMarketing from './pages/service-pages/DigitalMarketing';
import FrontEnd from './component/Layout/Frontend/FrontEnd';
import Error from './pages/error.js';
import AnimatedCursor from "react-animated-cursor"
import ScrollTopButton from './component/ScrollTopButton.js';

function App() {
	return (
		<div className="App">
			<AnimatedCursor
				innerSize={8}
				outerSize={35}
				innerScale={1}
				outerScale={2}
				outerAlpha={0}
				hasBlendMode={true}
				trailingSpeed={9}
				innerStyle={{
					backgroundColor: '#684DF4',
					transition: 'transform 0.3s ease'
				}}
				outerStyle={{
					border: '1px solid #684DF4',
					transition: 'transform 0.8s ease'
				}}
			/>
			<Router>
				<Routes>
					<Route path='/' element={<FrontEnd />}>
						<Route path='/' element={<Index />} />
						<Route path='/about' element={<About />} />
						<Route path='/contact' element={<Contact />} />
						<Route path='/services' element={<Services />} />
						{/* <Route path='/service-details' element={<ServiceDetails />} /> */}
						<Route path='/service-website-design-development' element={<WebsiteDesignDevelopment />} />
						{/* <Route path='/service-voice-support' element={<VoiceSupport />} /> */}
						{/* <Route path='/service-omni-support' element={<OmniSupport />} /> */}
						{/* <Route path='/service-virtual-learning' element={<VirtualLearning />} /> */}
						{/* <Route path='/service-accounting-and-bookkeeping-services' element={<AccountingAndBookkeepingServices />} /> */}
						{/* <Route path='/service-e-commerce-maintenance' element={<ECommerceMaintenance />} /> */}
						{/* <Route path='/service-data-management' element={<DataManagement />} /> */}
						<Route path='/service-content-writing' element={<ContentWriting />} />
						<Route path='/service-digital-marketing' element={<DigitalMarketing />} />
						<Route path='*' element={<Error />} />
					</Route>
				</Routes>
			</Router>
			<ScrollTopButton />
		</div>
	);
}

export default App;
