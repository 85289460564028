import React, { useState } from 'react'

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const closeMenu = () => {
        setIsMenuOpen(false);
    };
  return (
    <>            
        <div className={`th-menu-wrapper ${isMenuOpen ? 'th-body-visible' : ''}`}>
            <div className="th-menu-area text-center">
                <button className="th-menu-toggle" onClick={closeMenu}>
                    <i className="fal fa-times"></i>
                </button>
                <div className="mobile-logo">
                    <a href="/">
                        <img src="assets/img/logo.jpeg" alt="creativeandinnovative"  style={{ height: '90px', width: '140px', borderRadius: '55px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 11px 44px 22px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px' }} />
                    </a>
                </div>
                <div className="th-mobile-menu">
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/about">About Us</a></li>
                        <li><a href="/services">Services</a></li>
                        <li><a href="/contact">Contact</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <header className="th-header header-layout2">
            <div className="header-top">
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center gy-2">
                        <div className="col-auto d-none d-lg-block">
                            <div className="header-links">
                                <ul>
                                    <li><i className="fas fa-phone"></i><a href="tel:+919784710045">+91 97847 10045</a></li>
                                    <li><i className="fas fa-envelope"></i><a href="mailto:info@creativeandinnovative.com">info@creativeandinnovative.com</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="header-social"><span className="social-title">Follow Us On : </span>
                                <a href="https://www.facebook.com/"><i className="fab fa-facebook-f"></i></a>
                                <a href="https://www.twitter.com/"><i className="fab fa-twitter"></i></a>
                                <a href="https://www.linkedin.com/"><i className="fab fa-linkedin-in"></i></a>
                                <a href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a>
                                <a href="https://www.youtube.com/"><i className="fab fa-youtube"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sticky-wrapper">
                <div className="menu-area">
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-auto">
                                <div className="header-logo">
                                    <a className="icon-masking" href="/">
                                        <span data-mask-src="assets/img/logo.svg" className="mask-icon"></span>
                                        <img src="assets/img/logo.jpeg" alt="creativeandinnovative" style={{ height: '90px', width: '110px', borderRadius: '55px', boxShadow: '0 11px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col-auto">
                                <nav className="main-menu d-none d-lg-inline-block">
                                    <ul>
                                        <li><a href="/">Home</a></li>
                                        <li><a href="/about">About Us</a></li>
                                        <li><a href="/services">Services</a></li>                                        
                                        <li><a href="/contact">Contact</a></li>
                                    </ul>
                                </nav>
                                <div className="header-button">                                    
                                    <button className="th-menu-toggle d-inline-block d-lg-none" onClick={toggleMenu}>
                                        <i className="far fa-bars"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-auto d-none d-lg-block">
                                <div className="header-button">                                    
                                    <a href="/contact" className="th-btn shadow-none">
                                        Make Appointment
                                        <i className="fas fa-arrow-right ms-2"></i>
                                    </a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </>
  )
};

export default Header;