import React from 'react';
import ProductSlider from '../component/ProductSlider';
const Index = () => {
    const cardData = [
        `
        <div class="swiper-slide pt-5">
            <div class="service-grid">
                <div class="service-grid_icon">
                    <img src="assets/img/icon/service_card_1.svg" alt="Icon" />
                </div>
                <div class="service-grid_content">
                    <h3 class="box-title">
                        <a href="/service-website-design-development">
                            Website Design...
                        </a>
                    </h3>
                    <p class="service-grid_text">
                        We provide web design application development
                    </p>
                    <a href="/service-website-design-development" class="th-btn">
                        Read More
                        <i class="fas fa-arrow-right ms-2"></i>
                    </a>
                    <div class="bg-shape">
                        <img src="assets/img/bg/service_grid_bg.png" alt="bg" />
                    </div>
                </div>
            </div>
        </div>
        `,  
        `
        <div class="swiper-slide pt-5">
            <div class="service-grid">
                <div class="service-grid_icon">
                    <img src="assets/img/icon/service_card_2.svg" alt="Icon" />
                </div>
                <div class="service-grid_content">
                    <h3 class="box-title">
                        <a href="/service-content-writing">Content Writing</a>
                    </h3>
                    <p class="service-grid_text">
                        Content writing entailed drafting thoughts and business ideas
                    </p>
                    <a href="/service-content-writing" class="th-btn">
                        Read More
                        <i class="fas fa-arrow-right ms-2"></i>
                    </a>
                    <div class="bg-shape">
                        <img src="assets/img/bg/service_grid_bg.png" alt="bg" />
                    </div>
                </div>
            </div>
        </div>        
        `,
        `
        <div class="swiper-slide pt-5">
            <div class="service-grid">
                <div class="service-grid_icon">
                    <img src="assets/img/icon/service_card_3.svg" alt="Icon" />
                </div>
                <div class="service-grid_content">
                    <h3 class="box-title">
                        <a href="/service-digital-marketing">Digital Marketing</a>
                    </h3>
                    <p class="service-grid_text">
                        Driving Revenue with Strategic Online Solutions.
                    </p>
                    <a href="/service-digital-marketing" class="th-btn">
                        Read More
                        <i class="fas fa-arrow-right ms-2"></i>
                    </a>
                    <div class="bg-shape">
                        <img src="assets/img/bg/service_grid_bg.png" alt="bg" />
                    </div>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide pt-5">
            <div class="service-grid">
                <div class="service-grid_icon">
                    <img src="assets/img/icon/service_card_1.svg" alt="Icon" />
                </div>
                <div class="service-grid_content">
                    <h3 class="box-title">
                        <a href="/service-website-design-development">
                            Website Design...
                        </a>
                    </h3>
                    <p class="service-grid_text">
                        We provide web design application development
                    </p>
                    <a href="/service-website-design-development" class="th-btn">
                        Read More
                        <i class="fas fa-arrow-right ms-2"></i>
                    </a>
                    <div class="bg-shape">
                        <img src="assets/img/bg/service_grid_bg.png" alt="bg" />
                    </div>
                </div>
            </div>
        </div>
        `,  
        `
        <div class="swiper-slide pt-5">
            <div class="service-grid">
                <div class="service-grid_icon">
                    <img src="assets/img/icon/service_card_2.svg" alt="Icon" />
                </div>
                <div class="service-grid_content">
                    <h3 class="box-title">
                        <a href="/service-content-writing">Content Writing</a>
                    </h3>
                    <p class="service-grid_text">
                        Content writing entailed drafting thoughts and business ideas
                    </p>
                    <a href="/service-content-writing" class="th-btn">
                        Read More
                        <i class="fas fa-arrow-right ms-2"></i>
                    </a>
                    <div class="bg-shape">
                        <img src="assets/img/bg/service_grid_bg.png" alt="bg" />
                    </div>
                </div>
            </div>
        </div>        
        `,
        `
        <div class="swiper-slide pt-5">
            <div class="service-grid">
                <div class="service-grid_icon">
                    <img src="assets/img/icon/service_card_3.svg" alt="Icon" />
                </div>
                <div class="service-grid_content">
                    <h3 class="box-title">
                        <a href="/service-digital-marketing">Digital Marketing</a>
                    </h3>
                    <p class="service-grid_text">
                        Driving Revenue with Strategic Online Solutions.
                    </p>
                    <a href="/service-digital-marketing" class="th-btn">
                        Read More
                        <i class="fas fa-arrow-right ms-2"></i>
                    </a>
                    <div class="bg-shape">
                        <img src="assets/img/bg/service_grid_bg.png" alt="bg" />
                    </div>
                </div>
            </div>
        </div>
        `,
    ];
    const cardData2 = [
        `
        <div class="swiper-slide">
            <div class="project-grid">
                <div class="project-grid_img">
                    <img src="assets/img/project/project_2_1.jpg" alt="project image" /> 
                    <a href="assets/img/project/project_2_1.jpg" class="play-btn style3 popup-image">
                        <i class="far fa-plus"></i>
                    </a>
                </div>
                <div class="project-grid_content">
                    <h3 class="box-title">
                        <a>IT Consultency</a>
                    </h3>
                    <p class="project-grid_text">Distinctively enable premium expertise for
                        multifunctional action items. Energistically benchmark worldwide communities.
                    </p>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="project-grid">
                <div class="project-grid_img">
                    <img src="assets/img/project/project_2_2.jpg" alt="project image" /> 
                    <a href="assets/img/project/project_2_2.jpg" class="play-btn style3 popup-image">
                        <i class="far fa-plus"></i>
                    </a>
                </div>
                <div class="project-grid_content">
                    <h3 class="box-title">
                        <a>Web Development</a>
                    </h3>
                    <p class="project-grid_text">Distinctively enable premium expertise for
                        multifunctional action items. Energistically benchmark worldwide communities.
                    </p>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="project-grid">
                <div class="project-grid_img">
                    <img src="assets/img/project/project_2_3.jpg" alt="project image" />
                    <a href="assets/img/project/project_2_3.jpg" class="play-btn style3 popup-image">
                        <i class="far fa-plus"></i>
                    </a>
                </div>
                <div class="project-grid_content">
                    <h3 class="box-title">
                        <a>Website Design</a>
                    </h3>
                    <p class="project-grid_text">Distinctively enable premium expertise for
                        multifunctional action items. Energistically benchmark worldwide communities.
                    </p>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="project-grid">
                <div class="project-grid_img">
                    <img src="assets/img/project/project_2_4.jpg" alt="project image" />
                    <a href="assets/img/project/project_2_4.jpg" class="play-btn style3 popup-image">
                        <i class="far fa-plus"></i>
                    </a>
                </div>
                <div class="project-grid_content">
                    <h3 class="box-title">
                        <a>SEO Optimization</a>
                    </h3>
                    <p class="project-grid_text">Distinctively enable premium expertise for
                        multifunctional action items. Energistically benchmark worldwide communities.
                    </p>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="project-grid">
                <div class="project-grid_img">
                    <img src="assets/img/project/project_2_1.jpg" alt="project image" />
                    <a href="assets/img/project/project_2_1.jpg" class="play-btn style3 popup-image">
                        <i class="far fa-plus"></i>
                    </a>
                </div>
                <div class="project-grid_content">
                    <h3 class="box-title">
                        <a>IT Consultency</a>
                    </h3>
                    <p class="project-grid_text">Distinctively enable premium expertise for
                        multifunctional action items. Energistically benchmark worldwide communities.
                    </p>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="project-grid">
                <div class="project-grid_img">
                    <img src="assets/img/project/project_2_2.jpg" alt="project image" />
                    <a href="assets/img/project/project_2_2.jpg" class="play-btn style3 popup-image">
                        <i class="far fa-plus"></i>
                    </a>
                </div>
                <div class="project-grid_content">
                    <h3 class="box-title">
                        <a>Web Development</a>
                    </h3>
                    <p class="project-grid_text">Distinctively enable premium expertise for
                        multifunctional action items. Energistically benchmark worldwide communities.
                    </p>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="project-grid">
                <div class="project-grid_img">
                    <img src="assets/img/project/project_2_3.jpg" alt="project image" />
                    <a href="assets/img/project/project_2_3.jpg" class="play-btn style3 popup-image">
                        <i class="far fa-plus"></i>
                    </a>
                </div>
                <div class="project-grid_content">
                    <h3 class="box-title">
                        <a>Website Design</a>
                    </h3>
                    <p class="project-grid_text">Distinctively enable premium expertise for
                        multifunctional action items. Energistically benchmark worldwide communities.
                    </p>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="project-grid">
                <div class="project-grid_img">
                    <img src="assets/img/project/project_2_4.jpg" alt="project image" />
                    <a href="assets/img/project/project_2_4.jpg" class="play-btn style3 popup-image">
                        <i class="far fa-plus"></i>
                    </a>
                </div>
                <div class="project-grid_content">
                    <h3 class="box-title">
                        <a>SEO Optimization</a>
                    </h3>
                    <p class="project-grid_text">Distinctively enable premium expertise for
                        multifunctional action items. Energistically benchmark worldwide communities.
                    </p>
                </div>
            </div>
        </div>
        `
    ];
    const cardData3 = [
        `
        <div class="swiper-slide">
            <div class="th-team team-grid">
                <div class="team-img">
                    <img src="assets/img/team/team_3_1.jpg" alt="Team">
                </div>
                <div class="team-social">
                    <div class="play-btn">
                        <i class="far fa-plus"></i>
                    </div>
                    <div class="th-social">
                        <a target="_blank" href="https://facebook.com/">
                            <i class="fab fa-facebook-f"></i>
                        </a> 
                        <a target="_blank" href="https://twitter.com/">
                            <i class="fab fa-twitter"></i>
                        </a> 
                        <a target="_blank" href="https://instagram.com/">
                            <i class="fab fa-instagram"></i>
                        </a> 
                        <a target="_blank" href="https://linkedin.com/">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                    </div>
                </div>
                <h3 class="box-title">
                    <a>Rayan Athels</a>
                </h3>
                <span class="team-desig">Founder & CEO</span>
                <div class="box-particle" id="team-p1"></div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="th-team team-grid">
                <div class="team-img">
                    <img src="assets/img/team/team_3_2.jpg" alt="Team">
                </div>
                <div class="team-social">
                    <div class="play-btn">
                        <i class="far fa-plus"></i>
                    </div>
                    <div class="th-social">
                        <a target="_blank" href="https://facebook.com/">
                            <i class="fab fa-facebook-f"></i>
                        </a> 
                        <a target="_blank" href="https://twitter.com/">
                            <i class="fab fa-twitter"></i>
                        </a> 
                        <a target="_blank" href="https://instagram.com/">
                            <i class="fab fa-instagram"></i>
                        </a> 
                        <a target="_blank" href="https://linkedin.com/">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                    </div>
                </div>
                <h3 class="box-title">
                    <a>Alex Furnandes</a>
                </h3>
                <span class="team-desig">Project Manager</span>
                <div class="box-particle" id="team-p2"></div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="th-team team-grid">
                <div class="team-img">
                    <img src="assets/img/team/team_3_3.jpg" alt="Team">
                </div>
                <div class="team-social">
                    <div class="play-btn">
                        <i class="far fa-plus"></i>
                    </div>
                    <div class="th-social">
                        <a target="_blank" href="https://facebook.com/">
                            <i class="fab fa-facebook-f"></i>
                        </a> 
                        <a target="_blank" href="https://twitter.com/">
                            <i class="fab fa-twitter"></i>
                        </a> 
                        <a target="_blank" href="https://instagram.com/">
                            <i class="fab fa-instagram"></i>
                        </a> 
                        <a target="_blank" href="https://linkedin.com/">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                    </div>
                </div>
                <h3 class="box-title">
                    <a>Mary Crispy</a>
                </h3>
                <span class="team-desig">Cheif Expert</span>
                <div class="box-particle" id="team-p3"></div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="th-team team-grid">
                <div class="team-img">
                    <img src="assets/img/team/team_3_4.jpg" alt="Team">
                </div>
                <div class="team-social">
                    <div class="play-btn">
                        <i class="far fa-plus"></i>
                    </div>
                    <div class="th-social">
                        <a target="_blank" href="https://facebook.com/">
                            <i class="fab fa-facebook-f"></i>
                        </a> 
                        <a target="_blank" href="https://twitter.com/">
                            <i class="fab fa-twitter"></i>
                        </a> 
                        <a target="_blank" href="https://instagram.com/">
                            <i class="fab fa-instagram"></i>
                        </a> 
                        <a target="_blank" href="https://linkedin.com/">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                    </div>
                </div>
                <h3 class="box-title">
                    <a>Henry Joshep</a>
                </h3>
                <span class="team-desig">Product Manager</span>
                <div class="box-particle" id="team-p4"></div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="th-team team-grid">
                <div class="team-img">
                    <img src="assets/img/team/team_3_1.jpg" alt="Team">
                </div>
                <div class="team-social">
                    <div class="play-btn">
                        <i class="far fa-plus"></i>
                    </div>
                    <div class="th-social">
                        <a target="_blank" href="https://facebook.com/">
                            <i class="fab fa-facebook-f"></i>
                        </a> 
                        <a target="_blank" href="https://twitter.com/">
                            <i class="fab fa-twitter"></i>
                        </a> 
                        <a target="_blank" href="https://instagram.com/">
                            <i class="fab fa-instagram"></i>
                        </a> 
                        <a target="_blank" href="https://linkedin.com/">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                    </div>
                </div>
                <h3 class="box-title">
                    <a>Rayan Athels</a>
                </h3>
                <span class="team-desig">Founder & CEO</span>
                <div class="box-particle" id="team-p1"></div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="th-team team-grid">
                <div class="team-img">
                    <img src="assets/img/team/team_3_2.jpg" alt="Team">
                </div>
                <div class="team-social">
                    <div class="play-btn">
                        <i class="far fa-plus"></i>
                    </div>
                    <div class="th-social">
                        <a target="_blank" href="https://facebook.com/">
                            <i class="fab fa-facebook-f"></i>
                        </a> 
                        <a target="_blank" href="https://twitter.com/">
                            <i class="fab fa-twitter"></i>
                        </a> 
                        <a target="_blank" href="https://instagram.com/">
                            <i class="fab fa-instagram"></i>
                        </a> 
                        <a target="_blank" href="https://linkedin.com/">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                    </div>
                </div>
                <h3 class="box-title">
                    <a>Alex Furnandes</a>
                </h3>
                <span class="team-desig">Project Manager</span>
                <div class="box-particle" id="team-p2"></div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="th-team team-grid">
                <div class="team-img">
                    <img src="assets/img/team/team_3_3.jpg" alt="Team">
                </div>
                <div class="team-social">
                    <div class="play-btn">
                        <i class="far fa-plus"></i>
                    </div>
                    <div class="th-social">
                        <a target="_blank" href="https://facebook.com/">
                            <i class="fab fa-facebook-f"></i>
                        </a> 
                        <a target="_blank" href="https://twitter.com/">
                            <i class="fab fa-twitter"></i>
                        </a> 
                        <a target="_blank" href="https://instagram.com/">
                            <i class="fab fa-instagram"></i>
                        </a> 
                        <a target="_blank" href="https://linkedin.com/">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                    </div>
                </div>
                <h3 class="box-title">
                    <a>Mary Crispy</a>
                </h3>
                <span class="team-desig">Cheif Expert</span>
                <div class="box-particle" id="team-p3"></div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="th-team team-grid">
                <div class="team-img">
                    <img src="assets/img/team/team_3_4.jpg" alt="Team">
                </div>
                <div class="team-social">
                    <div class="play-btn">
                        <i class="far fa-plus"></i>
                    </div>
                    <div class="th-social">
                        <a target="_blank" href="https://facebook.com/">
                            <i class="fab fa-facebook-f"></i>
                        </a> 
                        <a target="_blank" href="https://twitter.com/">
                            <i class="fab fa-twitter"></i>
                        </a> 
                        <a target="_blank" href="https://instagram.com/">
                            <i class="fab fa-instagram"></i>
                        </a> 
                        <a target="_blank" href="https://linkedin.com/">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                    </div>
                </div>
                <h3 class="box-title">
                    <a>Henry Joshep</a>
                </h3>
                <span class="team-desig">Product Manager</span>
                <div class="box-particle" id="team-p4"></div>
            </div>
        </div>
        `
    ];
    const cardData4 = [
        `
        <div class="swiper-slide">
            <div class="testi-grid">
                <div class="testi-grid_img">
                    <img src="assets/img/testimonial/testi_3_1.jpg" alt="Avater">
                    <div class="testi-grid_quote">
                        <img src="assets/img/icon/quote_left_3.svg" alt="quote">
                    </div>
                </div>
                <div class="testi-grid_review">
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                </div>
                <div class="testi-grid_content">
                    <p class="testi-grid_text">Objectively visualize error-free technology for B2B
                        alignment. Monotonectally harness an expanded array of models via effective
                        collaboration. Globally synergize resource sucking value via cutting-edge.
                    </p>
                    <h3 class="box-title">David Farnandes</h3>
                    <p class="testi-grid_desig">CEO at Anaton</p>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="testi-grid">
                <div class="testi-grid_img">
                    <img src="assets/img/testimonial/testi_3_2.jpg" alt="Avater">
                    <div class="testi-grid_quote">
                        <img src="assets/img/icon/quote_left_3.svg" alt="quote">
                    </div>
                </div>
                <div class="testi-grid_review">
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                </div>
                <div class="testi-grid_content">
                    <p class="testi-grid_text">Objectively visualize error-free technology for B2B
                        alignment. Monotonectally harness an expanded array of models via effective
                        collaboration. Globally synergize resource sucking value via cutting-edge.
                    </p>
                    <h3 class="box-title">Jackline Techie</h3>
                    <p class="testi-grid_desig">CEO at Kormola</p>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="testi-grid">
                <div class="testi-grid_img">
                    <img src="assets/img/testimonial/testi_3_3.jpg" alt="Avater">
                    <div class="testi-grid_quote">
                        <img src="assets/img/icon/quote_left_3.svg" alt="quote">
                    </div>
                </div>
                <div class="testi-grid_review">
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                </div>
                <div class="testi-grid_content">
                    <p class="testi-grid_text">Objectively visualize error-free technology for B2B
                        alignment. Monotonectally harness an expanded array of models via effective
                        collaboration. Globally synergize resource sucking value via cutting-edge.
                    </p>
                    <h3 class="box-title">Abraham Khalil</h3>
                    <p class="testi-grid_desig">CEO at Anatora</p>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="testi-grid">
                <div class="testi-grid_img">
                    <img src="assets/img/testimonial/testi_3_4.jpg" alt="Avater">
                    <div class="testi-grid_quote">
                        <img src="assets/img/icon/quote_left_3.svg" alt="quote">
                    </div>
                </div>
                <div class="testi-grid_review">
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                </div>
                <div class="testi-grid_content">
                    <p class="testi-grid_text">Objectively visualize error-free technology for B2B
                        alignment. Monotonectally harness an expanded array of models via effective
                        collaboration. Globally synergize resource sucking value via cutting-edge.
                    </p>
                    <h3 class="box-title">Md Sumon Mia</h3>
                    <p class="testi-grid_desig">CEO at Rimasu</p>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="testi-grid">
                <div class="testi-grid_img">
                    <img src="assets/img/testimonial/testi_3_1.jpg" alt="Avater">
                    <div class="testi-grid_quote">
                        <img src="assets/img/icon/quote_left_3.svg" alt="quote">
                    </div>
                </div>
                <div class="testi-grid_review">
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                </div>
                <div class="testi-grid_content">
                    <p class="testi-grid_text">Objectively visualize error-free technology for B2B
                        alignment. Monotonectally harness an expanded array of models via effective
                        collaboration. Globally synergize resource sucking value via cutting-edge.
                    </p>
                    <h3 class="box-title">David Farnandes</h3>
                    <p class="testi-grid_desig">CEO at Anaton</p>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="testi-grid">
                <div class="testi-grid_img">
                    <img src="assets/img/testimonial/testi_3_2.jpg" alt="Avater">
                    <div class="testi-grid_quote">
                        <img src="assets/img/icon/quote_left_3.svg" alt="quote">
                    </div>
                </div>
                <div class="testi-grid_review">
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                </div>
                <div class="testi-grid_content">
                    <p class="testi-grid_text">Objectively visualize error-free technology for B2B
                        alignment. Monotonectally harness an expanded array of models via effective
                        collaboration. Globally synergize resource sucking value via cutting-edge.
                    </p>
                    <h3 class="box-title">Jackline Techie</h3>
                    <p class="testi-grid_desig">CEO at Kormola</p>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="testi-grid">
                <div class="testi-grid_img">
                    <img src="assets/img/testimonial/testi_3_3.jpg" alt="Avater">
                    <div class="testi-grid_quote">
                        <img src="assets/img/icon/quote_left_3.svg" alt="quote">
                    </div>
                </div>
                <div class="testi-grid_review">
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                </div>
                <div class="testi-grid_content">
                    <p class="testi-grid_text">Objectively visualize error-free technology for B2B
                        alignment. Monotonectally harness an expanded array of models via effective
                        collaboration. Globally synergize resource sucking value via cutting-edge.
                    </p>
                    <h3 class="box-title">Abraham Khalil</h3>
                    <p class="testi-grid_desig">CEO at Anatora</p>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="testi-grid">
                <div class="testi-grid_img">
                    <img src="assets/img/testimonial/testi_3_4.jpg" alt="Avater">
                    <div class="testi-grid_quote">
                        <img src="assets/img/icon/quote_left_3.svg" alt="quote">
                    </div>
                </div>
                <div class="testi-grid_review">
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                    <i class="fa-solid fa-star-sharp"></i>
                </div>
                <div class="testi-grid_content">
                    <p class="testi-grid_text">Objectively visualize error-free technology for B2B
                        alignment. Monotonectally harness an expanded array of models via effective
                        collaboration. Globally synergize resource sucking value via cutting-edge.
                    </p>
                    <h3 class="box-title">Md Sumon Mia</h3>
                    <p class="testi-grid_desig">CEO at Rimasu</p>
                </div>
            </div>
        </div>
        `
    ];
    const cardData5 = [
        `
        <div class="swiper-slide">
            <div class="blog-card">
                <div class="blog-img">
                    <img src="assets/img/blog/blog_1_1.jpg" alt="blog image">
                </div>
                <div class="blog-content">
                    <div class="blog-meta">
                        <a>
                            <i class="fal fa-calendar-days"></i>
                            15 Jan, 2024
                        </a> 
                        <a>
                            <i class="fal fa-comments"></i>
                            2 Comments
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a>
                            Unsatiable entreaties may collecting Power.
                        </a>
                    </h3>
                    <p class="blog-text">
                        Progressively plagiarize quality metrics for impactful data.
                        Assertively. Holisticly leverage existing magnetic.
                    </p>
                    <div class="blog-bottom">
                        <a class="author">
                            <img src="assets/img/blog/author-1-1.png" alt="avater">
                            By Themeholy
                        </a> 
                        <a class="line-btn">
                            Read More
                            <i class="fas fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="blog-card">
                <div class="blog-img">
                    <img src="assets/img/blog/blog_1_2.jpg" alt="blog image">
                </div>
                <div class="blog-content">
                    <div class="blog-meta">
                        <a>
                            <i class="fal fa-calendar-days"></i>
                            16 Jan, 2024
                        </a> 
                        <a>
                            <i class="fal fa-comments"></i>
                            3 Comments
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a>
                            Regional Manager & limited time
                            management.
                        </a>
                    </h3>
                    <p class="blog-text">
                        Progressively plagiarize quality metrics for impactful data.
                        Assertively. Holisticly leverage existing magnetic.
                    </p>
                    <div class="blog-bottom">
                        <a class="author">
                            <img src="assets/img/blog/author-1-1.png" alt="avater">
                            By Themeholy
                        </a> 
                        <a class="line-btn">
                            Read More
                            <i class="fas fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="blog-card">
                <div class="blog-img">
                    <img src="assets/img/blog/blog_1_3.jpg" alt="blog image">
                </div>
                <div class="blog-content">
                    <div class="blog-meta">
                        <a>
                            <i class="fal fa-calendar-days"></i>
                            17 Jan, 2024
                        </a> 
                        <a>
                            <i class="fal fa-comments"></i>
                            2 Comments
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a>
                            What’s the Holding Back the It Solution Industry?
                        </a>
                    </h3>
                    <p class="blog-text">Progressively plagiarize quality metrics for impactful data.
                        Assertively. Holisticly leverage existing magnetic.
                    </p>
                    <div class="blog-bottom">
                        <a class="author">
                            <img src="assets/img/blog/author-1-1.png" alt="avater"> 
                            By Themeholy
                        </a> 
                        <a class="line-btn">
                            Read More
                            <i class="fas fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="blog-card">
                <div class="blog-img">
                    <img src="assets/img/blog/blog_1_4.jpg" alt="blog image">
                </div>
                <div class="blog-content">
                    <div class="blog-meta">
                        <a>
                            <i class="fal fa-calendar-days"></i>
                            19 Jan, 2024
                        </a> 
                        <a>
                            <i class="fal fa-comments"></i>
                            4 Comments
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a>
                            Latin derived from Cicero's 1st-century BC
                        </a>
                    </h3>
                    <p class="blog-text">Progressively plagiarize quality metrics for impactful data.
                        Assertively. Holisticly leverage existing magnetic.
                    </p>
                    <div class="blog-bottom">
                        <a class="author">
                            <img src="assets/img/blog/author-1-1.png" alt="avater"> 
                            By Themeholy
                        </a> 
                        <a class="line-btn">
                            Read More
                            <i class="fas fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="blog-card">
                <div class="blog-img">
                    <img src="assets/img/blog/blog_1_1.jpg" alt="blog image">
                </div>
                <div class="blog-content">
                    <div class="blog-meta">
                        <a>
                            <i class="fal fa-calendar-days"></i>
                            15 Jan, 2024
                        </a> 
                        <a>
                            <i class="fal fa-comments"></i>
                            2 Comments
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a>
                            Unsatiable entreaties may collecting Power.
                        </a>
                    </h3>
                    <p class="blog-text">
                        Progressively plagiarize quality metrics for impactful data.
                        Assertively. Holisticly leverage existing magnetic.
                    </p>
                    <div class="blog-bottom">
                        <a class="author">
                            <img src="assets/img/blog/author-1-1.png" alt="avater">
                            By Themeholy
                        </a> 
                        <a class="line-btn">
                            Read More
                            <i class="fas fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="blog-card">
                <div class="blog-img">
                    <img src="assets/img/blog/blog_1_2.jpg" alt="blog image">
                </div>
                <div class="blog-content">
                    <div class="blog-meta">
                        <a>
                            <i class="fal fa-calendar-days"></i>
                            16 Jan, 2024
                        </a> 
                        <a>
                            <i class="fal fa-comments"></i>
                            3 Comments
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a>
                            Regional Manager & limited time
                            management.
                        </a>
                    </h3>
                    <p class="blog-text">
                        Progressively plagiarize quality metrics for impactful data.
                        Assertively. Holisticly leverage existing magnetic.
                    </p>
                    <div class="blog-bottom">
                        <a class="author">
                            <img src="assets/img/blog/author-1-1.png" alt="avater"> 
                            By Themeholy
                        </a> 
                        <a class="line-btn">
                            Read More
                            <i class="fas fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="blog-card">
                <div class="blog-img">
                    <img src="assets/img/blog/blog_1_3.jpg" alt="blog image">
                </div>
                <div class="blog-content">
                    <div class="blog-meta">
                        <a>
                            <i class="fal fa-calendar-days"></i>
                            17 Jan, 2024
                        </a> 
                        <a>
                            <i class="fal fa-comments"></i>
                            2 Comments
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a>
                            What’s the Holding Back the It Solution Industry?
                        </a>
                    </h3>
                    <p class="blog-text">
                        Progressively plagiarize quality metrics for impactful data. Assertively. Holisticly leverage existing magnetic.
                    </p>
                    <div class="blog-bottom">
                        <a class="author">
                            <img src="assets/img/blog/author-1-1.png" alt="avater">
                            By Themeholy
                        </a> 
                        <a class="line-btn">
                            Read More
                            <i class="fas fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        `,
        `
        <div class="swiper-slide">
            <div class="blog-card">
                <div class="blog-img">
                    <img src="assets/img/blog/blog_1_4.jpg" alt="blog image">
                </div>
                <div class="blog-content">
                    <div class="blog-meta">
                        <a>
                            <i class="fal fa-calendar-days"></i>
                            19 Jan, 2024
                        </a> 
                        <a>
                            <i class="fal fa-comments"></i>
                            4 Comments
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a>
                            Latin derived from Cicero's 1st-century BC
                        </a>
                    </h3>
                    <p class="blog-text">Progressively plagiarize quality metrics for impactful data.
                        Assertively. Holisticly leverage existing magnetic.
                    </p>
                    <div class="blog-bottom">
                        <a class="author">
                            <img src="assets/img/blog/author-1-1.png" alt="avater">
                            By Themeholy
                        </a> 
                        <a class="line-btn">
                            Read More
                            <i class="fas fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        `
    ];
    return (
        <>
            
            {/* <div className="cursor"></div>
            <div className="cursor2"></div>
            <div className="color-scheme-wrap active">
                <button className="switchIcon">
                    <i className="fa-solid fa-palette"></i>
                </button>
                <h4 className="color-scheme-wrap-title">
                    <i className="far fa-palette me-2"></i>
                    Style Swicher
                </h4>
                <div className="color-switch-btns">
                    <button data-color="#3E66F3">
                        <i className="fa-solid fa-droplet"></i>
                    </button>
                    <button data-color="#684DF4">
                        <i className="fa-solid fa-droplet"></i>
                    </button>
                    <button data-color="#008080">
                        <i className="fa-solid fa-droplet"></i>
                    </button>
                    <button data-color="#323F7C">
                        <i className="fa-solid fa-droplet"></i>
                    </button>
                    <button data-color="#FC3737">
                        <i className="fa-solid fa-droplet"></i>
                    </button>
                    <button data-color="#8a2be2">
                        <i className="fa-solid fa-droplet"></i>
                    </button>
                </div>
                <a href="https://themeforest.net/user/themeholy" className="th-btn text-center w-100">
                    <i className="fa fa-shopping-cart me-2"></i>
                    Purchase
                </a>
            </div>
            <div id="preloader" className="preloader">
                <button className="th-btn th-radius preloaderCls">Cancel Preloader</button>
                <div id="loader" className="th-preloader">
                    <div className="animation-preloader">
                        <div className="txt-loading">
                            <span preloader-text="W" className="characters">W</span>
                            <span preloader-text="E" className="characters">E</span>
                            <span preloader-text="B" className="characters">B</span>
                            <span preloader-text="T" className="characters">T</span>
                            <span preloader-text="E" className="characters">E</span>
                            <span preloader-text="C" className="characters">C</span>
                            <span preloader-text="K" className="characters">K</span>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="th-hero-wrapper hero-4" id="hero">
                <div className="body-particle" id="body-particle"></div>
                <div className="hero-img tilt-active"><img src="assets/img/hero/hero_img_4_1.png" alt="Hero Image" /></div>
                <div className="container">
                    <div className="hero-style4">
                        <div className="ripple-shape"><span className="ripple-1"></span><span className="ripple-2"></span><span
                            className="ripple-3"></span><span className="ripple-4"></span><span className="ripple-5"></span><span
                                className="ripple-6"></span></div><span className="hero-subtitle">Best Marketing Service</span>
                        <h1 className="hero-title">Make The Easiest</h1>
                        <h1 className="hero-title">Solution For You</h1>
                        <p className="hero-text">Energistically harness ubiquitous imperatives without state of the art
                            collaboration and idea-sharing. Monotonectally parallel task cross-unit experiences and front-end.
                        </p>
                        <div className="btn-group"><a href="/about" className="th-btn">ABOUT US<i
                            className="fa-regular fa-arrow-right ms-2"></i></a>
                            <div className="call-btn"><a href="/"
                                className="play-btn popup-video"><i className="fas fa-play"></i></a>
                                <div className="media-body"><a href="/"
                                    className="btn-title popup-video">Watch Our Story</a> <span className="btn-text">Subscribe
                                        Now</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="triangle-1"></div>
                <div className="triangle-2"></div>
                <div className="hero-shape2"><img src="assets/img/hero/hero_shape_2_2.png" alt="shape" /></div>
                <div className="hero-shape3"><img src="assets/img/hero/hero_shape_2_3.png" alt="shape" /></div>
            </div>
            <div className="overflow-hidden space">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 mb-30 mb-xl-0">
                            <div className="img-box4 tilt-active">
                                <div className="img-shape icon-masking">
                                    <span className="mask-icon" data-mask-src="assets/img/normal/about_3_1-shape.png" style={{ background: 'none' }}></span>
                                    <img src="assets/img/normal/about_3_1-shape.png" alt="img" /></div>
                                <img src="assets/img/normal/about_3_1.png" alt="About" /> <a
                                    href="/" className="play-btn popup-video"><i
                                        className="fas fa-play"></i></a>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="title-area mb-35 text-center text-xl-start">
                                <div className="shadow-title">ABOUT US</div><span className="sub-title">
                                    <div className="icon-masking me-2">
                                        <span className="mask-icon"
                                            data-mask-src="assets/img/theme-img/title_shape_2.svg" style={{ background: 'none' }}></span>
                                        <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" /></div>About WEBTECK IT
                                    SOLUTIONS
                                </span>
                                <h2 className="sec-title">We Are Increasing Business Success With <span
                                    className="text-theme">Technology</span></h2>
                            </div>
                            <p className="mt-n2 mb-30 text-center text-xl-start">Synergistically incentivize effective imperatives
                                through fully researched intellectual capital. Appropriately fashion client-based.</p>
                            <div className="achivement-tab filter-menu-active indicator-active"><button data-filter=".cat1"
                                className="active" type="button">Award Winning</button> <button data-filter=".cat2"
                                    type="button">Technology Index</button></div>
                            <div className="achivement-box-area filter-active-cat1 p-2">
                                <div className="filter-item w-100 cat1">
                                    <div className="achivement-box">
                                        <div className="achivement-box_img">
                                            <img src="assets/img/normal/about_3_1_1.jpg" alt="About" /></div>
                                        <div className="media-body">
                                            <h3 className="box-title">An Award-Winning Company.</h3>
                                            <p className="achivement-box_text">Monotonically matrix extensible applications and go
                                                forward communities. Synergistically extend client-based manufactured.</p><a
                                                    href="/about" className="th-btn">About More</a>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="filter-item w-100 cat2">
                                    <div className="achivement-box">
                                        <div className="achivement-box_img">
                                            <img src="assets/img/normal/about_3_1_2.jpg" alt="About" /></div>
                                        <div className="media-body">
                                            <h3 className="box-title">Having 25+ Years Of Experience.</h3>
                                            <p className="achivement-box_text">Monotonically matrix extensible applications and go
                                                forward communities. Synergistically extend client-based manufactured.</p><a
                                                    href="/about" className="th-btn">About More</a>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="bg-top-center z-index-common space-top" id="service-sec"
                style={{ backgroundImage: `url("assets/img/bg/service_bg_2.jpg")` }}>
                <div className="container" style={{ height: '450px' }}>
                    <div className="row justify-content-lg-between justify-content-center align-items-center">
                        <div className="col-lg-6 col-sm-9 pe-xl-5">
                            <div className="title-area text-center text-lg-start">
                                <div className="shadow-title color2">SERVICES</div><span className="sub-title">
                                    <div className="icon-masking me-2"><span className="mask-icon"
                                        data-mask-src="assets/img/theme-img/title_shape_2.svg" style={{ background: 'none' }}></span>
                                        <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" /></div>WHAT WE DO
                                </span>
                                <h2 className="sec-title text-white">We Provide Exclusive Service For <span className="text-theme">Your
                                    Business</span></h2>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="sec-btn"><a href="/services" className="th-btn style3">VIEW ALL SERVICES<i
                                className="fa-regular fa-arrow-right ms-2"></i></a></div>
                        </div>
                    </div>
                    <div className="slider-area">

                        <ProductSlider cardData={cardData} slidesPerView={3} />

                    </div>
                    {/* <div className="swiper-wrapper card-group">
                        <div className="swiper-slide card mx-2"
                            style={{ background: 'transparent', border: 'none' }}>
                            <div className="service-grid">
                                <div className="service-grid_icon">
                                    <img src="assets/img/icon/service_card_1.svg" alt="Icon" />
                                </div>
                                <div className="service-grid_content">
                                    <h3 className="box-title">
                                        <a href="/service-voice-support">
                                            Voice Support
                                        </a>
                                    </h3>
                                    <p className="service-grid_text">
                                        Prompt Solutions for Customer Delight.
                                    </p>
                                    <a href="/service-voice-support" className="th-btn">
                                        Read More
                                        <i className="fas fa-arrow-right ms-2"></i>
                                    </a>
                                    <div className="bg-shape">
                                        <img src="assets/img/bg/service_grid_bg.png" alt="bg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide card mx-2" style={{ background: 'transparent', border: 'none' }}>
                            <div className="service-grid">
                                <div className="service-grid_icon"><img src="assets/img/icon/service_card_2.svg" alt="Icon" />
                                </div>
                                <div className="service-grid_content">
                                    <h3 className="box-title">
                                        <a href="/service-data-management">Data Management</a>
                                    </h3>
                                    <p className="service-grid_text">
                                        Fueling Business Expansion with Efficient Management.
                                    </p>
                                    <a href="/service-data-management" className="th-btn">Read More
                                        <i className="fas fa-arrow-right ms-2"></i>
                                    </a>
                                    <div className="bg-shape">
                                        <img src="assets/img/bg/service_grid_bg.png" alt="bg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide card mx-2" style={{ background: 'transparent', border: 'none' }}>
                            <div className="service-grid">
                                <div className="service-grid_icon">
                                    <img src="assets/img/icon/service_card_3.svg" alt="Icon" />
                                </div>
                                <div className="service-grid_content">
                                    <h3 className="box-title">
                                        <a href="/service-digital-marketing">Digital Marketing</a>
                                    </h3>
                                    <p className="service-grid_text">
                                        Driving Revenue with Strategic Online Solutions.
                                    </p>
                                    <a href="/service-digital-marketing" className="th-btn">
                                        Read More
                                        <i className="fas fa-arrow-right ms-2"></i>
                                    </a>
                                    <div className="bg-shape">
                                        <img src="assets/img/bg/service_grid_bg.png" alt="bg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide card mx-2" style={{ background: 'transparent', border: 'none' }}>
                            <div className="service-grid">
                                <div className="service-grid_icon">
                                    <img src="assets/img/icon/service_card_5.svg" alt="Icon" />
                                </div>
                                <div className="service-grid_content">
                                    <h3 className="box-title">
                                        <a href="/service-omni-support">Omni Support</a>
                                    </h3>
                                    <p className="service-grid_text">
                                        Making Every Customer Feel Like Royalty.
                                    </p>
                                    <a href="/service-omni-support" className="th-btn">
                                        Read More
                                        <i className="fas fa-arrow-right ms-2"></i>
                                    </a>
                                    <div className="bg-shape">
                                        <img src="assets/img/bg/service_grid_bg.png" alt="bg" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div> */}

                </div>
            </section>
            <div className="why-sec-v2" style={{ backgroundImage: `url(assets/img/bg/why_bg_2.jpg)`, paddingTop: '550px' }}>
                <div className="container space">
                    <div className="row align-items-center flex-row-reverse" style={{ paddingTop: '100px' }}>
                        <div className="col-xl-6 mb-30 mb-xl-0">
                            <div className="img-box5"><img className="tilt-active" src="assets/img/normal/why_2_1.png" alt="Why" />
                                <div className="year-counter">
                                    <h3 className="year-counter_number"><span className="counter-number">10</span>k+</h3>
                                    <p className="year-counter_text">Clients Active</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="title-area mb-35">
                                <div className="shadow-title color3">Choose US</div><span className="sub-title">
                                    <div className="icon-masking me-2"><span className="mask-icon"
                                        data-mask-src="assets/img/theme-img/title_shape_2.svg" style={{ background: 'none' }}></span>
                                        <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" /></div>WHY CHOOSE US
                                </span>
                                <h2 className="sec-title">More Than 24+ Years Experience We Provide <span className="text-theme">IT
                                    Services</span></h2>
                            </div>
                            <p className="mt-n2 mb-30">Collaboratively envisioneer user friendly supply chains and cross unit
                                imperative. Authoritativel fabricate competitive resource and holistic. Holisticly restore real
                                time resources whereas standardized networks.</p>
                            <div className="feature-circle-wrap">
                                <div className="feature-circle">
                                    {/* <div className="progressbar" data-path-color="#684DF4">
                                        <div className="circle" data-percent="90">
                                            <div className="circle-num"></div>
                                        </div>
                                    </div> */}
                                    <div className="media-body">
                                        <h3 className="feature-circle_title">Business Grow</h3>
                                        <p className="feature-circle_text">Efficiently transition top-line ideas before market.</p>
                                    </div>
                                </div>
                                <div className="feature-circle">
                                    {/* <div className="progressbar" data-path-color="#684DF4">
                                        <div className="circle" data-percent="95">
                                            <div className="circle-num"></div>
                                        </div>
                                    </div> */}
                                    <div className="media-body">
                                        <h3 className="feature-circle_title">Quality Products</h3>
                                        <p className="feature-circle_text">Efficiently transition top-line ideas before market.</p>
                                    </div>
                                </div>
                            </div><a href="/about" className="th-btn">LEARN MORE<i
                                className="fa-regular fa-arrow-right ms-2"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <section className="space">
                <div className="container">
                    <div className="title-area text-center">
                        <div className="shadow-title">PROJECTS</div><span className="sub-title">
                            <div className="icon-masking me-2"><span className="mask-icon"
                                data-mask-src="assets/img/theme-img/title_shape_2.svg"
                                style={{ background: 'none' }}></span>
                                <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" /></div>LATEST PROJECTS
                        </span>
                        <h2 className="sec-title">Our Recent Latest <span className="text-theme">Projects</span></h2>
                    </div>
                    
                    <div className="swiper-wrapper">
                        <ProductSlider cardData={cardData2} slidesPerView={3} />
                    </div>
                    {/* <div className="card-group">
                        <div className="card mx-2" style={{ background: 'transparent', border: 'none' }}>
                            <div className="project-grid">
                                <div className="project-grid_img">
                                    <img src="assets/img/project/project_2_1.jpg" alt="project image" />
                                    <a href="assets/img/project/project_2_1.jpg"
                                        className="play-btn style3 popup-image"><i className="far fa-plus"></i></a></div>
                                <div className="project-grid_content">
                                    <h3 className="box-title"><a>IT Consultency</a></h3>
                                    <p className="project-grid_text">Distinctively enable premium expertise for
                                        multifunctional action items. Energistically benchmark worldwide communities.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card mx-2" style={{ background: 'transparent', border: 'none' }}>
                            <div className="project-grid">
                                <div className="project-grid_img">
                                    <img src="assets/img/project/project_2_2.jpg" alt="project image" />
                                    <a href="assets/img/project/project_2_2.jpg"
                                        className="play-btn style3 popup-image"><i className="far fa-plus"></i></a></div>
                                <div className="project-grid_content">
                                    <h3 className="box-title"><a>Web Development</a></h3>
                                    <p className="project-grid_text">Distinctively enable premium expertise for
                                        multifunctional action items. Energistically benchmark worldwide communities.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card mx-2" style={{ background: 'transparent', border: 'none' }}>
                            <div className="project-grid">
                                <div className="project-grid_img">
                                    <img src="assets/img/project/project_2_3.jpg" alt="project image" />
                                    <a href="assets/img/project/project_2_3.jpg"
                                        className="play-btn style3 popup-image"><i className="far fa-plus"></i></a></div>
                                <div className="project-grid_content">
                                    <h3 className="box-title"><a>Website Design</a></h3>
                                    <p className="project-grid_text">Distinctively enable premium expertise for
                                        multifunctional action items. Energistically benchmark worldwide communities.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div> */}
                </div>
            </section>
            
            <section className="bg-smoke" id="process-sec" style={{ backgroundImage: `url(assets/img/bg/process_bg_1.png)` }}>
                <div className="container space">
                    <div className="title-area text-center">
                        <div className="shadow-title">PROCESS</div><span className="sub-title">
                            <div className="icon-masking me-2"><span className="mask-icon"
                                style={{ background: 'none' }}
                                data-mask-src="assets/img/theme-img/title_shape_2.svg"></span>
                                <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" /></div>WORK PROCESS
                        </span>
                        <h2 className="sec-title">How to work <span className="text-theme">it!</span></h2>
                    </div>
                    <div className="process-card-area">
                        <div className="process-line"><img src="assets/img/bg/process_line.svg" alt="line" /></div>
                        <div className="row gy-40">
                            <div className="col-sm-6 col-xl-3 process-card-wrap">
                                <div className="process-card">
                                    <div className="process-card_number">01</div>
                                    <div className="process-card_icon"><img src="assets/img/icon/process_card_1.svg" alt="icon" />
                                    </div>
                                    <h2 className="box-title">Select a project</h2>
                                    <p className="process-card_text">Continua scale empowered metrics with cost effective
                                        innovation.</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-3 process-card-wrap">
                                <div className="process-card">
                                    <div className="process-card_number">02</div>
                                    <div className="process-card_icon"><img src="assets/img/icon/process_card_2.svg" alt="icon" />
                                    </div>
                                    <h2 className="box-title">Project analysis</h2>
                                    <p className="process-card_text">Continua scale empowered metrics with cost effective
                                        innovation.</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-3 process-card-wrap">
                                <div className="process-card">
                                    <div className="process-card_number">03</div>
                                    <div className="process-card_icon"><img src="assets/img/icon/process_card_3.svg" alt="icon" />
                                    </div>
                                    <h2 className="box-title">Plan Execute</h2>
                                    <p className="process-card_text">Continua scale empowered metrics with cost effective
                                        innovation.</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-3 process-card-wrap">
                                <div className="process-card">
                                    <div className="process-card_number">04</div>
                                    <div className="process-card_icon"><img src="assets/img/icon/process_card_4.svg" alt="icon" />
                                    </div>
                                    <h2 className="box-title">Deliver result</h2>
                                    <p className="process-card_text">Continua scale empowered metrics with cost effective
                                        innovation.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="" id="team-sec">
                
                <div className="container space">
                    <div className="title-area text-center">
                        <div className="shadow-title">Team</div><span className="sub-title">
                            <div className="icon-masking me-2"><span className="mask-icon"
                                data-mask-src="assets/img/theme-img/title_shape_2.svg"
                                style={{ background: 'none' }}></span>
                                <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" /></div>GREAT TEAM
                        </span>
                        <h2 className="sec-title">See Our Skilled Expert <span className="text-theme">Team</span></h2>
                    </div>
                    <div className="swiper-wrapper">
                        <ProductSlider cardData={cardData3} slidesPerView={3} />
                    </div>
                </div>
                <div className="shape-mockup" data-top="0" data-right="0"><img src="assets/img/shape/tech_shape_1.png" alt="shape" />
                </div>
                <div className="shape-mockup" data-top="0%" data-left="0%"><img src="assets/img/shape/square_1.png" alt="shape" />
                </div>
            </section>
            <section className="bg-top-center space" style={{ backgroundImage: `url(assets/img/bg/testi_bg_3.jpg)` }}>
                <div className="container" style={{ height: '330px' }}>
                    <div className="title-area text-center">
                        <div className="shadow-title color2">TESTIMONIALS</div><span className="sub-title">
                            <div className="icon-masking me-2"><span className="mask-icon"
                                data-mask-src="assets/img/theme-img/title_shape_2.svg"
                                style={{ background: 'none' }}></span>
                                <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" /></div>CUSTOMER FEEDBACK
                        </span>
                        <h2 className="sec-title text-white">What Happy Clients Says<br /><span className="text-theme">About Us?</span>
                        </h2>
                    </div>
                    {/* <div className="slider-area">
                        <div className="swiper th-slider has-shadow" id="testiSlider3"
                            data-slider-options='{"loop":true,"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"2"},"1200":{"slidesPerView":"3"}}}'>
                        <div className="swiper-wrapper card-group">
                            <div className="swiper-slide card mx-2" style={{ background: 'transparent', border: 'none' }} >
                                <div className="testi-grid">
                                    <div className="testi-grid_img">
                                        <img src="assets/img/testimonial/testi_3_1.jpg" alt="Avater" />
                                        <div className="testi-grid_quote">
                                            <img src="assets/img/icon/quote_left_3.svg" alt="quote" /></div>
                                    </div>
                                    <div className="testi-grid_review"><i className="fa-solid fa-star-sharp"></i><i
                                        className="fa-solid fa-star-sharp"></i><i className="fa-solid fa-star-sharp"></i><i
                                            className="fa-solid fa-star-sharp"></i><i className="fa-solid fa-star-sharp"></i></div>
                                    <div className="testi-grid_content">
                                        <p className="testi-grid_text">Objectively visualize error-free technology for B2B
                                            alignment. Monotonectally harness an expanded array of models via effective
                                            collaboration. Globally synergize resource sucking value via cutting-edge.</p>
                                        <h3 className="box-title">David Farnandes</h3>
                                        <p className="testi-grid_desig">CEO at Anaton</p>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide card mx-2" style={{ background: 'transparent', border: 'none' }}>
                                <div className="testi-grid">
                                    <div className="testi-grid_img">
                                        <img src="assets/img/testimonial/testi_3_2.jpg" alt="Avater" />
                                        <div className="testi-grid_quote">
                                            <img src="assets/img/icon/quote_left_3.svg" alt="quote" /></div>
                                    </div>
                                    <div className="testi-grid_review"><i className="fa-solid fa-star-sharp"></i><i
                                        className="fa-solid fa-star-sharp"></i><i className="fa-solid fa-star-sharp"></i><i
                                            className="fa-solid fa-star-sharp"></i><i className="fa-solid fa-star-sharp"></i></div>
                                    <div className="testi-grid_content">
                                        <p className="testi-grid_text">Objectively visualize error-free technology for B2B
                                            alignment. Monotonectally harness an expanded array of models via effective
                                            collaboration. Globally synergize resource sucking value via cutting-edge.</p>
                                        <h3 className="box-title">Jackline Techie</h3>
                                        <p className="testi-grid_desig">CEO at Kormola</p>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide card mx-2" style={{ background: 'transparent', border: 'none' }}>
                                <div className="testi-grid">
                                    <div className="testi-grid_img">
                                        <img src="assets/img/testimonial/testi_3_3.jpg" alt="Avater" />
                                        <div className="testi-grid_quote">
                                            <img src="assets/img/icon/quote_left_3.svg" alt="quote" /></div>
                                    </div>
                                    <div className="testi-grid_review"><i className="fa-solid fa-star-sharp"></i><i
                                        className="fa-solid fa-star-sharp"></i><i className="fa-solid fa-star-sharp"></i><i
                                            className="fa-solid fa-star-sharp"></i><i className="fa-solid fa-star-sharp"></i></div>
                                    <div className="testi-grid_content">
                                        <p className="testi-grid_text">Objectively visualize error-free technology for B2B
                                            alignment. Monotonectally harness an expanded array of models via effective
                                            collaboration. Globally synergize resource sucking value via cutting-edge.</p>
                                        <h3 className="box-title">Abraham Khalil</h3>
                                        <p className="testi-grid_desig">CEO at Anatora</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        </div>

                    </div> */}
                    <div className="slider-area">
                        <div className="swiper-wrapper">
                            <ProductSlider cardData={cardData4} slidesPerView={3} />
                        </div>
                    </div>
                </div>
            </section>
            <section className="space-bottom">
                <div className="container" style={{ paddingTop: '350px' }}>
                    <div className="cta-box">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="cta-box_img"><img src="assets/img/normal/customer_support.jpg" alt="Imag" /></div>
                            </div>
                            <div className="col-lg-7">
                                <div className="cta-box_content">
                                    <div className="cta-box_icon"><img src="assets/img/icon/call_1.svg" alt="Icon" /></div>
                                    <div className="title-area mb-35">
                                        <div className="shadow-title">CONSULTATION</div><span className="sub-title">
                                            <div className="icon-masking me-2"><span className="mask-icon"
                                                data-mask-src="assets/img/theme-img/title_shape_2.svg"
                                                style={{ background: 'none' }}></span>
                                                <img
                                                    src="assets/img/theme-img/title_shape_2.svg" alt="shape" /></div>LET’S
                                            CONSULTATION
                                        </span>
                                        <h2 className="sec-title">Let’s Talk About Business Solutions <span className="text-theme">With
                                            Us</span></h2>
                                    </div><a href="/contact" className="th-btn">CONTACT US<i
                                        className="fa-regular fa-arrow-right ms-2"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="bg-top-right bg-smoke overflow-hidden space" id="blog-sec"
                style={{ backgroundImage: `url(assets/img/bg/blog_bg_1.png)` }}>
                <div className="container">
                    <div className="title-area text-center">
                        <div className="shadow-title color3">Update</div><span className="sub-title">
                            <div className="icon-masking me-2"><span className="mask-icon"
                                data-mask-src="assets/img/theme-img/title_shape_2.svg"
                                style={{ background: 'none' }}></span>
                                <img
                                    src="assets/img/theme-img/title_shape_2.svg" alt="shape" /></div>NEWS & ARTICLES
                        </span>
                        <h2 className="sec-title">Get Every Single Update <span className="text-theme">Blog</span></h2>
                    </div>
                    
                    <div className="swiper-wrapper">
                        <ProductSlider cardData={cardData5} slidesPerView={3} />
                    </div>
                </div>
                <div className="shape-mockup" data-bottom="0" data-left="0" style={{ width: '-webkit-fill-available' }}>
                    <div className="particle-2 small" id="particle-4" style={{ width: '-webkit-fill-available' }}></div>
                </div>

            </section> */}
            
            <div className="scroll-top"><svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                    style={{
                        transition: 'stroke-dashoffset 10ms linear 0s',
                        strokeDasharray: '307.919, 307.919',
                        strokeDashoffset: '307.919'
                    }}>
                </path>
            </svg></div>
           
        </>
    )
};

export default Index;