import React from 'react';
const Contact = () => {
    return (
        <>
            <div className="breadcumb-wrapper"
                style={{
                    backgroundImage: `url("assets/img/bg/breadcumb-bg.jpg")`,
                    backgroundPosition: 'center'
                }}
            >
                <div className="container">
                    <div className="breadcumb-content">
                        <h1 className="breadcumb-title">Contact Us</h1>
                        <ul className="breadcumb-menu">
                            <li><a href="/">Home</a></li>
                            <li>Contact Us</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="space">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-xl-4 col-md-6">
                            <div className="contact-info" style={{height: '100%'}}>
                                <div className="contact-info_icon"><i className="fas fa-location-dot"></i></div>
                                <div className="media-body">
                                    <h4 className="box-title">Our Office Address</h4><span className="contact-info_text">100 Feet Rd, Shobhagpura, Udaipur, Rajasthan 313001</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="contact-info" style={{height: '100%'}}>
                                <div className="contact-info_icon"><i className="fas fa-phone"></i></div>
                                <div className="media-body">
                                    <h4 className="box-title">Call Us Anytime</h4>
                                    <span className="contact-info_text">
                                        <a href="tel:+919784710045">+91 97847 10045</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="contact-info" style={{height: '100%'}}>
                                <div className="contact-info_icon"><i className="fas fa-envelope"></i></div>
                                <div className="media-body">
                                    <h4 className="box-title">Send An Email</h4>
                                    <span className="contact-info_text">
                                        <a href="mailto:info@creativeandinnovative.com">info@creativeandinnovative.com</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-smoke space"
                style={{
                    backgroundImage: `url("assets/img/bg/contact_bg_1.png")`
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8">
                            <div className="title-area mb-35 text-xl-start text-center"><span className="sub-title">
                                <div className="icon-masking me-2"><span className="mask-icon"
                                    data-mask-src="assets/img/theme-img/title_shape_2.svg" style={{ background: 'none' }}></span>
                                    <img
                                        src="assets/img/theme-img/title_shape_2.svg" alt="shape" /></div>contact with us!
                            </span>
                                <h2 className="sec-title">Have Any Questions?</h2>
                                <p className="sec-text">Enthusiastically disintermediate one-to-one leadership via business
                                    e-commerce. Dramatically reintermediate compelling process improvements rather than
                                    empowered relationships.</p>
                            </div>
                            <form action="https://html.themeholy.com/webteck/demo/mail.php" method="POST"
                                className="contact-form ajax-contact">
                                <div className="row">
                                    <div className="form-group col-md-6"><input type="text" className="form-control" name="name"
                                        id="name" placeholder="Your Name" /> <i className="fal fa-user"></i></div>
                                    <div className="form-group col-md-6"><input type="email" className="form-control" name="email"
                                        id="email" placeholder="Email Address" /> <i className="fal fa-envelope"></i></div>
                                    <div className="form-group col-md-6"><select name="subject" id="subject" className="form-select">
                                        <option value="" disabled="disabled" hidden>Select Subject
                                        </option>
                                        <option value="Web Development">Web Development</option>
                                        <option value="Brand Marketing">Brand Marketing</option>
                                        <option value="UI/UX Designing">UI/UX Designing</option>
                                        <option value="Digital Marketing">Digital Marketing</option>
                                    </select> <i className="fal fa-chevron-down"></i></div>
                                    <div className="form-group col-md-6"><input type="tel" className="form-control" name="number"
                                        id="number" placeholder="Phone Number" /> <i className="fal fa-phone"></i></div>
                                    <div className="form-group col-12"><textarea name="message" id="message" cols="30" rows="3"
                                        className="form-control" placeholder="Your Message"></textarea> <i
                                            className="fal fa-comment"></i></div>
                                    <div className="form-btn text-xl-start text-center col-12"><button className="th-btn">Send Message<i
                                        className="fa-regular fa-arrow-right ms-2"></i></button></div>
                                </div>
                                <p className="form-messages mb-0 mt-3"></p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="scroll-top"><svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                    style={{
                        transition: 'stroke-dashoffset 10ms linear 0s',
                        strokeDasharray: '307.919, 307.919',
                        strokeDashoffset: '307.919'
                    }}>
                </path>
            </svg></div>
            
        </>
    )
};

export default Contact;