import React from 'react';

const ContentWriting = () => {
    return (
        <>
            <div className="breadcumb-wrapper" style={{
                backgroundImage: `url("assets/img/bg/breadcumb-bg.jpg")`,
                backgroundPosition: 'center'
            }} data-bg-src="">
                <div className="container">
                    <div className="breadcumb-content">
                        <h1 className="breadcumb-title">Services Details</h1>
                        <ul className="breadcumb-menu">
                            <li><a href="/">Home</a></li>
                            <li><a href="/services">Services</a></li>
                            <li>Content Writing</li>
                        </ul>
                    </div>
                </div>
            </div>
            <section className="space-top space-extra-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-8 col-lg-8">
                            <div className="page-single">
                                <div className="page-img"><img src="assets/img/service/service_details.jpg" alt="Service Image" />
                                </div>
                                <div className="page-content">
                                    <h2 className="h3 page-title">Content Writing</h2>
                                    <h4>
                                        Overview
                                    </h4>
                                    <p>
                                        Content writing entailed drafting thoughts and business ideas on paper. It is the process of planning, researching, writing and editing the available content. Content writing work could include writing blogs, articles, a thesis, scripts for videos, podcasts, and academic content. Content writing is a preliminary part of curating sentences into a well-written paper. Quality content upscales communication skills with access to nuanced information for deciphering creatively crafted messages. <br />
                                        Accurate content about the preferred working styles and work of businesses benefits customers in gaining information about the company methods. The main goal of content writing is to speak to a large audience base with receptive and well-crafted messages. <br />
                                        Creative and Innovative Service Provider provides services in different segments of content writing. These include:
                                    </p>
                                    <h4>
                                        Social Media
                                    </h4>
                                    <p>
                                        When a business wants to upscale the provision of its services, social media is the preferred outlet for expansion. Online platforms such as Facebook, Instagram, YouTube, and X (Twitter) are utilised to direct the attention of readers toward the company. Once people start following the social media account, the business has the opportunity to convey relevant information to the readers by posting work-related content and creative information.

                                    </p>
                                    <h4>
                                        Web Content
                                    </h4>
                                    <p>
                                        Everything that you read online comes under the category of web content. Reputed organisations delegate the key responsibility of reaching a wider audience with an online presence and space for creative web content development. The data represents the brand image and integrates information related to the company's stance and market dynamics.
                                    </p>
                                    <h4>
                                        Journalism and Public Relations
                                    </h4>
                                    <p>
                                        Journalism is an event-based and factual representation of instances across the globe. This type of writing spans a variety of topics including criminal reports, case studies, business reports, economics analysis, worldwide crises, pandemics and other real-world issues with subjective consequences.
                                    </p>
                                    <h4>
                                        Academic Writing
                                    </h4>
                                    <p>
                                        Students utilise academic content writing globally for in-depth research and analysis. Sample projects enable students to cater to the core requirements of their assessments and foster curiosity and learning. The sample papers help students get better grades and acquire an abundance of knowledge in their respective courses.
                                    </p>
                                    <h4>
                                        Business Plan
                                    </h4>
                                    <p>
                                        A business plan is a unique segment of content writing that essentially caters to individuals seeking to introduce a start-up or looking for opportunities to expand their business.
                                    </p>
                                    <h4>
                                        Industry-specific Writing
                                    </h4>
                                    <p>
                                        Industry-specific content is written by subject matter experts (SMEs) who have initiated and indulged in particular research-based mechanisms. These articles are written by doctors, attorneys, scientists, educators, and scholarly professionals with expert knowledge of their respective fields. Industry-specific writing is precise and effective in getting adequate information from their readers. <br />
                                        Creative and Innovative Service Provider assists with the aforementioned services. Our knowledgeable and skilled experts are dedicated to their job responsibilities. We write effective content with high-end credibility to assist our clientele in their profession. <br />
                                        Students may feel blue and stressed due to their inability to compose an assignment or project that matches the academic standard of their university. This can result in hampered academic results and build student frustration. In such situations, when students find it difficult to formulate an academically adept piece of writing due to a lack of domain-specific knowledge, they must consider academic content writing to assist them with the structure and relevant topics of their assignment. Our subject matter experts compose numerous assignments per day and can generate an assignment with ease and accuracy.
                                    </p>

                                    <h4>
                                        How We Help
                                    </h4>
                                    <p className="mb-30">
                                        Writing can help you improve your ability to focus on intricate details of routine life that can cater to creative writing. Writing enables a healthy articulation of ideas and boosts student creativity. We provide content writing services in the UK that can help you brainstorm and come up with innovative solutions for the project at hand. <br />
                                        As an academic student, it is crucial to set goal-specific objectives and work persistently to attain the desired outcome. Since the assignment writing services have a designated time, breaking down the assignment to meet distinct demands can integrate time management skills for future-oriented projects. This is a useful activity for your academic life and builds the skills of adaptability, articulation, and most importantly, time management. <br />
                                        This is a useful activity for your academic life and builds the skills of adaptability, articulation, and time management.
                                    </p>

                                    <div className="row">
                                        <div className="col-md-6 mb-30">
                                            <div className="th-video"><img className="w-100"
                                                src="assets/img/service/service_inner_1.jpg" alt="service" /> <a
                                                    href="https://www.youtube.com/watch?v=_sI_Ps7JSEk"
                                                    className="play-btn popup-video"><i className="fas fa-play"></i></a></div>
                                        </div>
                                        <div className="col-md-6 mb-30">
                                            <h3 className="h4 mb-20">We Serve The Best Work</h3>
                                            <div className="checklist style5">
                                                <ul>
                                                    <li><i className="fas fa-octagon-check"></i> Up am intention on dependent
                                                        questions</li>
                                                    <li><i className="fas fa-octagon-check"></i> Surrounded affronting favourable
                                                    </li>
                                                    <li><i className="fas fa-octagon-check"></i> Sed ut perspiciatis unde omnis iste
                                                        natus</li>
                                                    <li><i className="fas fa-octagon-check"></i> Special Careness Risk Free</li>
                                                    <li><i className="fas fa-octagon-check"></i> Bibend auctor nisi elit volume are
                                                        beguiled</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-4">Credibly brand accurate data after user friendly ROI. Professionally
                                        negotiate inexpensive markets and team driven scenarios. Interactively seize wireless
                                        e-tailers after resource maximizing content. Collaboratively leverage existing.</p>
                                    <h3 className="h4 mb-20">Benefits With Our Service</h3>
                                    <div className="service-feature-wrap">
                                        <div className="service-feature">
                                            <div className="service-feature_icon"><img src="assets/img/icon/service_feature_1.svg"
                                                alt="icon" /></div>
                                            <div className="media-body">
                                                <h4 className="service-feature_title">Flexible Solutions</h4>
                                                <p className="service-feature_text">Completely grow multimedia based content before
                                                    global scenarios.</p>
                                            </div>
                                        </div>
                                        <div className="service-feature">
                                            <div className="service-feature_icon"><img src="assets/img/icon/service_feature_2.svg"
                                                alt="icon" /></div>
                                            <div className="media-body">
                                                <h4 className="service-feature_title">24/7 Unlimited Support</h4>
                                                <p className="service-feature_text">Completely grow multimedia based content before
                                                    global scenarios.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 className="h4 mt-35 mb-4">Questions About Service</h3>
                                    <div className="accordion-area accordion" id="faqAccordion">
                                        <div className="accordion-card style2 active">
                                            <div className="accordion-header" id="collapse-item-1"><button className="accordion-button"
                                                type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1"
                                                aria-expanded="true" aria-controls="collapse-1">Where can I get analytics
                                                help?</button></div>
                                            <div id="collapse-1" className="accordion-collapse collapse show"
                                                aria-labelledby="collapse-item-1" data-bs-parent="#faqAccordion">
                                                <div className="accordion-body">
                                                    <p className="faq-text">Synergistically evisculate ubiquitous niches whereas
                                                        holistic resources. Assertively evolve prospective methods of
                                                        empowerment before diverse web-readiness. Seamlessly incentivize
                                                        market-driven schemas rather than clicks-and-mortar benefits.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-card style2">
                                            <div className="accordion-header" id="collapse-item-2"><button
                                                className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse-2" aria-expanded="false"
                                                aria-controls="collapse-2">How long should a business plan be?</button>
                                            </div>
                                            <div id="collapse-2" className="accordion-collapse collapse"
                                                aria-labelledby="collapse-item-2" data-bs-parent="#faqAccordion">
                                                <div className="accordion-body">
                                                    <p className="faq-text">Synergistically evisculate ubiquitous niches whereas
                                                        holistic resources. Assertively evolve prospective methods of
                                                        empowerment before diverse web-readiness. Seamlessly incentivize
                                                        market-driven schemas rather than clicks-and-mortar benefits.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-card style2">
                                            <div className="accordion-header" id="collapse-item-3"><button
                                                className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse-3" aria-expanded="false"
                                                aria-controls="collapse-3">How To Choose A Good QA Consultant?</button>
                                            </div>
                                            <div id="collapse-3" className="accordion-collapse collapse"
                                                aria-labelledby="collapse-item-3" data-bs-parent="#faqAccordion">
                                                <div className="accordion-body">
                                                    <p className="faq-text">Synergistically evisculate ubiquitous niches whereas
                                                        holistic resources. Assertively evolve prospective methods of
                                                        empowerment before diverse web-readiness. Seamlessly incentivize
                                                        market-driven schemas rather than clicks-and-mortar benefits.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-lg-4">
                            <aside className="sidebar-area">
                                <div className="widget widget_nav_menu">
                                    <h3 className="widget_title">All Services</h3>
                                    <div className="menu-all-pages-container">
                                        <ul className="menu">
                                            <li><a href="/service-website-design-development">Website Design And Development</a></li>
                                            <li><a href="/service-content-writing">Content Writing</a></li>
                                            <li><a href="/service-digital-marketing">Digital Marketing</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="widget widget_download">
                                    <h4 className="widget_title">Download Brochure</h4>
                                    <div className="download-widget-wrap"><a  className="th-btn"><i
                                        className="fa-light fa-file-pdf me-2"></i>DOWNLOAD PDF</a> <a
                                             className="th-btn style5"><i
                                                className="fa-light fa-file-lines me-2"></i>DOWNLOAD DOC</a></div>
                                </div>
                                <div className="widget widget_banner"
                                    style={{
                                        backgroundImage: `url("assets/img/bg/widget_banner.jpg")`
                                    }} data-bg-src="">
                                    <div className="widget-banner"><span className="text">CONTACT US NOW</span>
                                        <h2 className="title">You Need Help?</h2><a href="/contact" className="th-btn style3">GET A
                                            QUOTE<i className="fas fa-arrow-right ms-2"></i></a>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            
            <div className="scroll-top"><svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                    style={{
                        transition: 'stroke-dashoffset 10ms linear 0s',
                        strokeDasharray: '307.919, 307.919',
                        strokeDashoffset: '307.919'
                    }}>
                </path>
            </svg></div>
        </>
    )
};

export default ContentWriting;